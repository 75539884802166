/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/auth/AuthContext";
import { PatientContext } from "../context/patient/PatientContext";
import { RenderLoading } from "./RenderAlert";
import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  ScaleFade,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ProfileInfo } from "./ProfileInfo";
import { ProfilePassword } from "./ProfilePassword";

export const ProfilePatientContent = () => {
  const { usuario } = useContext(AuthContext);
  const { getPatient, patient } = useContext(PatientContext);

  const [isLoading, setIsLoading] = useState(false);

  const fullname = patient?.apellidos
    ? `${patient?.nombre} ${patient?.apellidos}`
    : `${patient?.nombre}`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getPatient(usuario.id_paciente);
      setIsLoading(false);
      return;
    };

    fetchData();
  }, []);
  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in>
          <Card
            boxShadow={"md"}
            borderWidth="1px"
            borderColor="gray.200"
            bg={"white.300"}
            rounded={"lg"}
          >
            <CardBody>
              <Box>
                <Flex justify="center" align="center">
                  <Avatar boxSize={"5rem"} bg="teal.500" mr={4} />
                  <VStack align={"flex-start"}>
                    <Heading as="h2" size={"xl"} color={"gray.600"}>
                      {fullname}
                    </Heading>
                    <Text>{patient?.email}</Text>
                  </VStack>
                </Flex>

                <Flex direction={{ base: "column", md: "row" }}>
                  <ProfileInfo user={patient} />

                  <ProfilePassword user={usuario} />
                </Flex>
              </Box>
            </CardBody>
          </Card>
        </ScaleFade>
      )}
    </>
  );
};
