import React from "react";
import {
  Box,
  Container,
  Text,
  Button,
  Badge,
  Grid,
  Tooltip,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";

export const FeedbackCard = ({
  autor,
  comentario,
  email,
  estado,
  fecha_creacion,
  id,
  titulo,
}) => {
  return (
    <Container mt={8}>
      <Grid templateColumns="repeat(auto-fill, minmax(200px, 1fr))" gap={2}>
        <Box
          maxW="sm"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
        >
          <Box p={4}>
            <Text fontSize="xl" fontWeight="semibold">
              {titulo}
            </Text>
            <Box mb={3}>
              <Text fontSize="sm" color="gray.400">
                {`Autor: ${autor} / ${email}`}
              </Text>
            </Box>
            <Text color="gray.600" lineHeight="1.5">
              {`Descripción: ${comentario}`}
            </Text>
          </Box>

          <Box
            p={4}
            borderTopWidth="1px"
            borderTopColor="gray.200"
            d="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="sm" color="gray.500">
              {fecha_creacion}
            </Text>
            <Badge colorScheme={estado === "Atendido" ? "green" : "red"}>
              {estado}
            </Badge>
            <Tooltip label="Editar Estado" hasArrow>
              <Button
                size="xs"
                variant="ghost"
                colorScheme="blue"
                leftIcon={<FaEdit />}
                // onClick={() => onEdit(id)}
              >
                Editar
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};
