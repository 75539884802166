import {
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import { FeedbackCard } from "./FeedbackCard";

export const FeedbackTabs = ({ feedbacks }) => {
  const resolvedFeedbacks = feedbacks.filter(
    (feedback) => feedback.estado === "Atendido"
  );
  const pendingFeedbacks = feedbacks.filter(
    (feedback) => feedback.estado === "Pendiente"
  );
  return (
    <Tabs variant="enclosed-colored" size="lg" isLazy>
      <TabList mb="1em">
        <Tab>Pendientes</Tab>
        <Tab>Atendidos</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={{ base: 2, lg: 4 }}
          >
            {pendingFeedbacks.map((feedback) => (
              <FeedbackCard key={feedback.id} {...feedback} />
            ))}
          </SimpleGrid>
        </TabPanel>
        <TabPanel>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={{ base: 5, lg: 8 }}
          >
            {resolvedFeedbacks.map((feedback) => (
              <FeedbackCard key={feedback.id} {...feedback} />
            ))}
          </SimpleGrid>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
