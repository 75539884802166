/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { SurgeryContext } from "../context/surgery/SurgeryContext";
import { RenderLoading } from "../components/RenderAlert";
import { Box, Container, ScaleFade } from "@chakra-ui/react";
import { TablaCateteres } from "../components/Tablas/TablaCateteres";
import { CrearCateterUI } from "../components/Ui/CrearCateterUI";

export const CathetersScreen = () => {
  const { getsCatheters, catheters } = useContext(SurgeryContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getsCatheters();
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            shadow={"md"}
            rounded={"md"}
            maxW={"full"}
            py={8}
          >
            <Box mt={6}>
              <CrearCateterUI />
              <TablaCateteres catheters={catheters} />
            </Box>
          </Container>
        </ScaleFade>
      )}
    </>
  );
};
