import React, { useContext } from "react";
import { Container } from "@chakra-ui/react";
import AuthContext from "../context/auth/AuthContext";
import { ProfileProContent } from "../components/ProfileProContent";
import { ProfilePatientContent } from "../components/ProfilePatientContent";

export const ProfileScreen = () => {
  const { rol } = useContext(AuthContext);

  return (
    <Container maxW={"full"}>
      {rol.nombre === "Profesional" ? (
        <ProfileProContent />
      ) : (
        <ProfilePatientContent />
      )}
    </Container>
  );
};
