/* eslint-disable import/no-anonymous-default-export */
import { LOGIN_LOADING, LOGIN_SUCCESS, LOGOUT } from "./actions";

export default (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoginLoading: false,
        usuario: payload.user,
        rol: payload.user.rol,
      };

    case LOGOUT:
      return {
        usuario: payload,
        isAutenticado: false,
      };

    case LOGIN_LOADING:
      return {
        isLoginLoading: payload,
      };

    default:
      return state;
  }
};
