import { Box, Heading, Container, Text, Stack } from "@chakra-ui/react";

export const HeroSection = () => {
  return (
    <>
      <Container maxW={"full"} bg={"white"} rounded={"lg"} shadow={"md"}>
        <Stack
          direction={"column"}
          align={{ base: "center", md: "flex-start" }}
          spacing={{ base: 4, md: 8 }}
          py={{ base: 8, md: 16 }}
        >
          <Box flex={1} alignSelf={"center"}>
            <Heading
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "4xl", md: "5xl" }}
              lineHeight={"120%"}
              color={"gray.600"}
            >
              ¡Bienvenidos a la plataforma Mi Doble J!
            </Heading>
            <Text mt={4} color={"gray.600"} textAlign="center">
              Plataforma dedicada a educar en cirugías con catéter doble j!"
            </Text>
          </Box>

          <Box
            flex={1}
            position="relative"
            minW={{ base: "100%", sm: "50%" }}
            minH={{ base: 200, md: 300 }}
            alignSelf={"center"}
          >
            <iframe
              style={{ position: "absolute", width: "100%", height: "100%" }}
              src="https://www.youtube-nocookie.com/embed/8X3lyhJ7kkI?loop=1&mute=1"
              title="cateter midoblej"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Box>
        </Stack>
      </Container>
    </>
  );
};
