/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { SurgeryContext } from "../context/surgery/SurgeryContext";
import { RenderLoading } from "../components/RenderAlert";
import AuthContext from "../context/auth/AuthContext";
import {
  Container,
  Heading,
  ScaleFade,
  Select,
  VStack,
} from "@chakra-ui/react";
import { ReportsOptionsScreen } from "./ReportsOptionsScreen";

export const ReportScreen = () => {
  const { usuario } = useContext(AuthContext);
  const { getsSurgeriesByPatient, surgeries } = useContext(SurgeryContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSurgery, setSelectedSurgery] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await getsSurgeriesByPatient(usuario.id_paciente);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSurgerySelect = (event) => {
    setSelectedSurgery(event.target.value);
  };

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            shadow={"md"}
            rounded={"md"}
            maxW={"full"}
            py={8}
          >
            <VStack spacing={4}>
              <Heading as="h2" size="lg" textAlign="center">
                Seleccione una cirugía para reportar
              </Heading>
              <Select
                placeholder="Seleccionar cirugía"
                onChange={handleSurgerySelect}
              >
                {surgeries.map((surgery) => (
                  <option key={surgery.id} value={surgery.id}>
                    {`${surgery?.tipoCirugium.descripcion} - ${surgery.doctor_tratante} - ${surgery.fecha_creacion}`}
                  </option>
                ))}
              </Select>

              {selectedSurgery && (
                <ReportsOptionsScreen idSurgery={selectedSurgery} />
              )}
            </VStack>
          </Container>
        </ScaleFade>
      )}
    </>
  );
};
