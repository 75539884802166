import {
  Box,
  CloseButton,
  Divider,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FaClipboardList,
  FaEnvelopeOpenText,
  FaNotesMedical,
  // FaPenSquare,
  FaPollH,
  FaSyringe,
  FaUserMd,
  FaUsers,
} from "react-icons/fa";
import { NavItem } from "./NavItem";
import AuthContext from "../../context/auth/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const SidebarContent = ({ onClose, ...rest }) => {
  const { rol } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Box
          className="bg__login--img"
          onClick={handleGoHome}
          _hover={{ cursor: "pointer" }}
        />

        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>

      <Flex height={"100%"} direction={"column"} gap={1}>
        {rol.crear_paciente && (
          <NavItem
            key={"pacientes"}
            icon={FaUsers}
            navigateTo="/pacientes"
            onClick={onClose}
          >
            Pacientes
          </NavItem>
        )}

        {rol.crear_profesional && (
          <NavItem
            key={"usuarios"}
            icon={FaUserMd}
            navigateTo="/usuarios"
            onClick={onClose}
          >
            Profesionales
          </NavItem>
        )}

        {rol.ver_datos && (
          <NavItem
            key={"datos-investigacion"}
            icon={FaNotesMedical}
            navigateTo="/datos-investigacion"
            onClick={onClose}
          >
            Datos Investigación
          </NavItem>
        )}

        {rol.crear_cateter && (
          <>
            <NavItem
              key={"cateter"}
              icon={FaSyringe}
              navigateTo="/cateteres"
              onClick={onClose}
            >
              Catéter
            </NavItem>

            <NavItem
              key={"sugerencias"}
              icon={FaEnvelopeOpenText}
              navigateTo="/sugerencias"
              onClick={onClose}
            >
              Sugerencias
            </NavItem>
          </>
        )}

        {rol.crear_reporte && (
          <>
            <NavItem
              key={"mis_cirugias"}
              icon={FaClipboardList}
              navigateTo={`/mis_cirugias`}
              onClick={onClose}
            >
              Mis cirugías y reportes
            </NavItem>
            <NavItem
              key={"reporte"}
              icon={FaPollH}
              navigateTo="/reportar"
              onClick={onClose}
            >
              Reportar
            </NavItem>
          </>
        )}

        <Divider borderWidth={1} mt={2} />
        <Text
          as="b"
          color={"gray.500"}
          alignSelf={"center"}
          justifyContent={"flex-end"}
          fontSize={"0.7rem"}
          mt={2}
        >
          Versión: 0.5
        </Text>
      </Flex>
    </Box>
  );
};
