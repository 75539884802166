import React from "react";
import { Button, Icon, Spinner } from "@chakra-ui/react";

const BaseButton = ({
  children,
  colorScheme,
  fn,
  isLoading = false,
  color = "black",
}) => {
  return (
    <Button
      _active={{ transform: "scale(0.96)" }}
      colorScheme={colorScheme}
      onClick={fn}
      display="flex"
      ml={4}
      shadow={"md"}
      isLoading={isLoading}
      color={color}
    >
      {children}
    </Button>
  );
};

const BaseButtonSpinner = ({ children, isLoading, fn }) => {
  return (
    <Button
      _active={{ transform: "scale(0.96)" }}
      _hover={{ bg: "blue.500" }}
      bg={"blue.400"}
      boxShadow="md"
      color={"white"}
      display="flex"
      onClick={fn}
      isLoading={isLoading}
      loadingText="Cargando..."
      mb={3}
      type="submit"
      spinner={
        <Spinner
          thickness="4px"
          speed="0.65s"
          color="blue.500"
          size="md"
          bg="transparent"
        />
      }
    >
      {children}
    </Button>
  );
};

export const ButtonWithIcon = ({
  fn,
  titleIcon,
  titleButton,
  isLoading,
  colorScheme = "cyan",
  color = "white",
}) => {
  return (
    <BaseButton
      colorScheme={colorScheme}
      color={color}
      fn={fn}
      isLoading={isLoading}
    >
      <Icon as={titleIcon} mr={2} boxSize={5} />
      {titleButton}
    </BaseButton>
  );
};

export const ButtonNormal = ({ fn, titleButton }) => {
  return (
    <BaseButton colorScheme="cyan" color="white" fn={fn}>
      {titleButton}
    </BaseButton>
  );
};

export const ButtonWithSpinner = ({ fn, titleButton, isLoading }) => {
  return (
    <BaseButtonSpinner fn={fn} isLoading={isLoading}>
      {titleButton}
    </BaseButtonSpinner>
  );
};
