/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FaSyringe } from "react-icons/fa";
import { ButtonWithIcon } from "../CustomButtons";
import { SurgeryContext } from "../../context/surgery/SurgeryContext";
import { CatheterForm } from "../Forms/CatheterForm";

const initialValues = {
  marca: "",
  modelo: "",
  descripcion: "",
  longitud: "",
  grosor: "",
};

export const CrearCateterUI = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createCatheter } = useContext(SurgeryContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const handleCreate = async (values, { setSubmitting }) => {
    setIsLoading(true);
    await createCatheter({ ...values });
    setSubmitting(false);
    setIsLoading(false);
    setFormValues(initialValues);
    onClose();
  };

  return (
    <>
      <ButtonWithIcon
        fn={onOpen}
        titleIcon={FaSyringe}
        titleButton={"Nuevo Catéter"}
      />

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nuevo Catéter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CatheterForm
              initialValues={formValues}
              isLoading={isLoading}
              onSubmit={handleCreate}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
