import axios from "axios";

export const API = axios.create({
  //URL DE LOCAL
  // baseURL: "http://localhost:4008/api/v1",
  // baseURL:
  //   "http://ec2-18-191-39-181.us-east-2.compute.amazonaws.com:4006/api/v1",

  //URL DE DEV
  // baseURL: "http://ec2-3-23-160-103.us-east-2.compute.amazonaws.com:4006/api/v1",

  //URL DE TESTING
  // baseURL: "https://doblej.lanekapp.com:8008/api/v1",

  //URL DE PROD
  baseURL: "https://midoblej.com:8009/api/v1",
});
