import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  FaCheckCircle,
  FaEye,
  FaEyeSlash,
  FaLock,
  FaSave,
  FaTimesCircle,
} from "react-icons/fa";

import { API } from "../api/api";
import { ButtonWithIcon } from "./CustomButtons";
import useForm from "../hooks/useForm";

export const ProfilePassword = ({ user }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formValues, handleInputChange, reset] = useForm({
    password: "",
    confirmedPassword: "",
  });

  const { password, confirmedPassword } = formValues;
  const passwordsMatch = password === confirmedPassword;
  const bothFieldsFilled = password !== "" && confirmedPassword !== "";

  const handleSubmit = async (e) => {
    e.preventDefault();
    onClose();
    setIsLoading(true);

    await API.put(`/usuario/cambiar-password/${user.id_usuario}`, {
      password,
    });
    toast({
      description: "La contraseña se ha actualizado.",
      status: "success",
      position: "bottom-left",
      variant: "left-accent",
      duration: 4000,
      isClosable: true,
    });

    setIsLoading(false);
    reset();
  };
  return (
    <>
      <Container mt={12} fontSize={"lg"} color={"gray.600"}>
        <HStack mb={4}>
          <Icon as={FaLock} />
          <Text as={"b"} fontSize={"xl"}>
            Cambiar contraseña
          </Text>
        </HStack>
        <Box spacing={"lg"}>
          <form>
            <FormControl id="password" isRequired mb={2}>
              <FormLabel>Nueva contraseña</FormLabel>
              <InputGroup>
                <Input
                  autoComplete="off"
                  onChange={handleInputChange}
                  name="password"
                  value={password}
                  type={showPassword ? "text" : "password"}
                />
                <InputRightElement h={"full"}>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? (
                      <Icon as={FaEyeSlash} />
                    ) : (
                      <Icon as={FaEye} />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl id="confirmedPassword" isRequired>
              <FormLabel>Confirmar contraseña</FormLabel>
              <InputGroup>
                <Input
                  autoComplete="off"
                  onChange={handleInputChange}
                  name="confirmedPassword"
                  value={confirmedPassword}
                  type={showPassword ? "text" : "password"}
                />
                <InputRightElement h={"full"}>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? (
                      <Icon as={FaEyeSlash} />
                    ) : (
                      <Icon as={FaEye} />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            {bothFieldsFilled && (
              <Box my={2}>
                <Text
                  as="b"
                  fontSize={"1rem"}
                  color={passwordsMatch ? "green.500" : "red.500"}
                >
                  {passwordsMatch ? (
                    <Box>
                      <Icon as={FaCheckCircle} mr={2} />
                      Las contraseñas coinciden.
                    </Box>
                  ) : (
                    <Box>
                      <Icon as={FaTimesCircle} mr={2} />
                      Las contraseñas no coinciden.
                    </Box>
                  )}
                </Text>
              </Box>
            )}

            {bothFieldsFilled && passwordsMatch && (
              <ButtonWithIcon
                fn={() => onOpen()}
                isLoading={isLoading}
                titleButton={"Guardar cambios"}
                titleIcon={FaSave}
              />
            )}
          </form>
        </Box>
      </Container>

      <Modal
        isCentered
        isOpen={isOpen}
        size={"sm"}
        onClose={onClose}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent py={4}>
          <ModalBody>¿Estas seguro de cambiar tu contraseña?</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Confirmar
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
