import React, { useContext } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Link,
  Stack,
} from "@chakra-ui/react";
import AuthContext from "../../context/auth/AuthContext";
import { ButtonWithSpinner } from "../../components/CustomButtons";
import { useForm } from "../../hooks/useForm";
import { useNavigate } from "react-router-dom";

const LoginScreen = () => {
  const navigate = useNavigate();
  const { startLogin, isLoginLoading, startLoading } = useContext(AuthContext);

  const [formValues, handleInputChange] = useForm({
    email: "",
    password: "",
  });

  const { email, password } = formValues;

  const handleLogin = async (e) => {
    e.preventDefault();
    startLoading(true);
    await startLogin(email, password);
  };

  return (
    <>
      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack mx={"auto"} w={"md"} maxW={"lg"} py={12} px={6}>
            <Box rounded={"lg"} boxShadow={"lg"} p={8}>
              <Stack spacing={4}>
                <form className="form__login--box">
                  <FormControl mb={3}>
                    <FormLabel>Correo electrónico</FormLabel>
                    <Input
                      autoComplete="off"
                      borderColor="#a9a9a9"
                      className="form__login--input"
                      name="email"
                      onChange={handleInputChange}
                      type="email"
                      value={email}
                      _hover={{
                        borderColor: "#a9a9a9",
                      }}
                      _focus={{ borderColor: "#262626" }}
                    />
                  </FormControl>

                  <FormControl mb={3}>
                    <FormLabel>Contraseña</FormLabel>
                    <Input
                      borderColor="#a9a9a9"
                      className="form__login--input"
                      name="password"
                      onChange={handleInputChange}
                      type="password"
                      value={password}
                      _hover={{
                        borderColor: "#a9a9a9",
                      }}
                      _focus={{ borderColor: "#262626" }}
                    />
                  </FormControl>

                  <ButtonWithSpinner
                    fn={handleLogin}
                    titleButton="Iniciar sesión"
                    isLoading={isLoginLoading}
                  />
                  <Link
                    color="blue.500"
                    fontSize="sm"
                    mt={2}
                    alignSelf="flex-start"
                    onClick={() => {
                      navigate("/auth/check-mail");
                    }}
                  >
                    ¿Has olvidado tu contraseña?
                  </Link>
                </form>

                <div className="bg__login--img" />
              </Stack>
            </Box>
          </Stack>
        </Flex>
        <Flex flex={1} display={{ base: "none", lg: "flex" }}>
          <Image
            alt={"Login Image"}
            objectFit={"cover"}
            src={
              "https://images.pexels.com/photos/4021769/pexels-photo-4021769.jpeg?auto=compress&cs=tinysrgb&w=1600"
            }
          />
        </Flex>
      </Stack>
    </>
  );
};

export default LoginScreen;
