/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Input,
  ScaleFade,
  useBreakpointValue,
} from "@chakra-ui/react";
import { CrearPacienteUI } from "../components/Ui/CrearPacienteUI";
import { TablaPaciente } from "../components/Tablas/TablaPaciente";
import { RenderLoading } from "../components/RenderAlert";
import { PatientContext } from "../context/patient/PatientContext";

export const PatientsScreen = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { getsPatients, patients } = useContext(PatientContext);

  const pacientesFiltrados = () => {
    if (search.length === 0) return patients;

    // Si hay algo en la barra de busqueda
    const filtrados = patients.filter((paciente) => {
      return (
        paciente.nombre?.toLowerCase().includes(search.toLowerCase()) ||
        paciente.rut?.toString().includes(search)
      );
    });
    return filtrados;
  };

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
  };

  const placeholder = useBreakpointValue({
    base: "Buscar",
    md: "Buscar paciente por nombre o rut",
  });

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        setIsLoading(true);
        await getsPatients();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPatients();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            shadow={"md"}
            rounded={"md"}
            maxW={"full"}
            py={8}
          >
            <Box display="flex" justifyContent="center" mb={4}>
              <Input
                boxShadow="md"
                htmlSize={40}
                size="md"
                width="auto"
                variant="filled"
                value={search}
                onChange={onSearchChange}
                placeholder={`${placeholder}`}
              />

              <CrearPacienteUI />
            </Box>

            <TablaPaciente patients={pacientesFiltrados()} />
          </Container>
        </ScaleFade>
      )}
    </>
  );
};
