/* 
    maneja todas las funciones del Patient context que engloba todas las acciones asociadas al paciente
*/

import React, { useContext, useReducer } from "react";
import { useToast } from "@chakra-ui/react";
import { PatientContext } from "./PatientContext";
import { PatientReducer } from "./PatientReducer";
import { API } from "../../api/api";
import AuthContext from "../auth/AuthContext";

export const PatientState = ({ children }) => {
  const toast = useToast();
  const { logoutRequest, usuario } = useContext(AuthContext);

  const initialState = {
    patients: [],
    patient: {},
  };

  const [state, dispatch] = useReducer(PatientReducer, initialState);

  const getsPatients = async () => {
    const endpoint = "/paciente";
    try {
      const { data } = await API.get(endpoint);

      dispatch({
        type: "GET_PATIENTS",
        payload: data.patients,
      });
    } catch (error) {
      handleError(error, "Obtener pacientes");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getPatient = async (idpaciente) => {
    const endpoint = `paciente/${idpaciente}`;
    try {
      const { data } = await API.get(endpoint);

      dispatch({
        type: "GET_PATIENT",
        payload: data.item,
      });
    } catch (error) {
      handleError(error, "Obtener paciente");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const createPatient = async (data) => {
    const endpoint = "/paciente/nuevo-paciente";
    // const token = localStorage.getItem("token");

    try {
      await API.post(
        endpoint,
        { ...data, id_profesional: usuario.id_profesional }
        // { headers: { Authorization: token } }
      );

      await getsPatients();

      toast({
        description: "Paciente creado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      logoutRequest();
      handleError(error, "Crear paciente");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const updatePatient = async (data) => {
    const { id: idpaciente } = data;
    const endpoint = `/paciente/${idpaciente}`;
    try {
      await API.put(endpoint, { ...data });
      await getPatient(idpaciente);
      toast({
        description: "Paciente actualizado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error, "Actualizar paciente");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const deletePatient = async (id) => {
    const endpoint = `/paciente/${id}`;
    try {
      await API.delete(endpoint);
      toast({
        description: "Paciente eliminado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error, "Borrar paciente");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const createQuestionnaire = async (data) => {
    // console.log(data);
    const endpoint = "/cuestionario/nuevo-cuestionario";
    try {
      await API.post(endpoint, data);

      toast({
        description: "Cuestionario creado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error, "Crear cuestionario");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleError = async (error, process) => {
    try {
      const bodyLog = {
        correo: usuario.email,
        codigo: error.response.status,
        proceso: process,
        mensaje: error.response.data.message,
        respuesta: error,
      };
      await API.post("/logs", bodyLog);
      console.log(error);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PatientContext.Provider
      value={{
        patients: state.patients,
        patient: state.patient,
        reports: state.reports,
        getsPatients,
        getPatient,
        createPatient,
        updatePatient,
        deletePatient,
        createQuestionnaire,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};
