import React from "react";
import { Center } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { InputNormal, InputRut } from "./InputForm";
import { ButtonWithIcon } from "../CustomButtons";
import { FaSave } from "react-icons/fa";

const validationSchema = Yup.object({
  rut: Yup.string().matches(
    /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/,
    "RUT inválido"
  ),
  nombre: Yup.string().required("Obligatorio"),
  apellidos: Yup.string().required("Obligatorio"),
  email: Yup.string().email("Correo inválido").required("Obligatorio"),
});

export const UsuarioForm = ({
  handleRutChange,
  initialValues,
  isLoading,
  onSubmit,
  rut,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        values = initialValues,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Form>
          <InputRut
            label="RUT"
            name="rut"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleRutChange}
            value={rut}
          />
          {touched.rut && errors.rut ? <span>{errors.rut}</span> : null}

          <InputNormal
            label="Nombre"
            name="nombre"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.nombre}
          />
          {touched.nombre && errors.nombre ? <div>{errors.nombre}</div> : null}

          <InputNormal
            label="Apellidos"
            name="apellidos"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.apellidos}
          />
          {touched.apellidos && errors.apellidos ? (
            <div>{errors.apellidos}</div>
          ) : null}

          <InputNormal
            label="Correo electrónico"
            name="email"
            type="email"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.email}
          />
          {touched.email && errors.email ? <div>{errors.email}</div> : null}

          <InputNormal
            label="Institución"
            name="institucion"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.institucion}
          />

          <InputNormal
            label="Teléfono"
            name="telefono"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.telefono}
          />

          <Center mt={5}>
            <ButtonWithIcon
              fn={handleSubmit}
              titleButton="Guardar"
              titleIcon={FaSave}
              isLoading={isLoading}
            />
          </Center>
        </Form>
      )}
    </Formik>
  );
};
