import React, { useContext, useState } from "react";
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Image,
  Textarea,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  TableContainer,
  Table,
  Thead,
  Th,
  Tr,
  RadioGroup,
  Radio,
  Stack,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import imghombre from "../assets/img/questionnaire/imghombre.png";
import imgmujer from "../assets/img/questionnaire/imgmujer.png";
import { PatientContext } from "../context/patient/PatientContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ButtonWithIcon } from "../components/CustomButtons";
import { FaReply } from "react-icons/fa";

export const QuestionnaireScreen = () => {
  const { idcirugia } = useParams();
  const { pathname } = useLocation();
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(12.5);
  const { createQuestionnaire } = useContext(PatientContext);
  const [gender, setGender] = useState();
  const navigate = useNavigate();
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");
  const [isTextareaDisabled, setIsTextareaDisabled] = useState(true);
  const [isSelectDisabled, setIsSelectDisabled] = useState(true);  
  const [checkedItemsHM, setCheckedItemsHM] = useState("");
  const [checkedItemsM, setCheckedItemsM] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);  
  
  const [formularioValues, setFormularioValues] = useState({
    form1: {
      genero: "",
    },
    form2: {
      u1: "",
      u2: "",
      u3: "",
      u4: "",
      u5: "",
      u6: "",
      u7: "",
      u8: "",
      u9: "",
      u10: "",
      u11: "",
    },
    form3: {
      p1h: "",
      p2h: "",
      p3h: "",
      p1m: "",
      p2m: "",
      p3m: "",
    },
    form4: {
      p4: "",
      p5: "",
      p6: "",
      p7: "",
      p8: "",
      p9: "",
    },
    form5: {
      g1: "",
      g2: "",
      g3: "",
      g4: "",
      g5: "",
      g6: "",
    },
    form6: {
      w1: "",
      razones: "",
      w2: "",
      w3: "",
      w4: "",
      tipo_trabajo: "",
      w5: "",
      w6: "",
      w7: "",
    },
    form7: {
      s1: "",
      s2: "",
      motivo_interrupcion: "",
      s3: "",
      s4: "",
    },
    form8: {
      a1: "",
      a2: "",
      a3: "",
      a4: "",
      gq: "",
      aq: "",
    },
  });

  const [pasoActual, setPasoActual] = useState(1);

  const handleChange = (event) => {
    if (event.target) {
      const { name, value } = event.target;
      setFormularioValues((prevValues) => ({
        ...prevValues,
        [pasoActual]: {
          ...prevValues[pasoActual],
          [name]: value,
        },
      }));
    } else {
      setFormularioValues((prevValues) => ({
        ...prevValues,
        [pasoActual]: {
          ...prevValues[pasoActual],
          [event.target.name]: event.target.value,
        },
      }));
    }
  };

  const handleFormSubmitGender = (event) => {
    setGender(event.target.value);
    if (event.target) {
      const { name, value } = event.target;
      setFormularioValues((prevValues) => ({
        ...prevValues,
        [pasoActual]: {
          ...prevValues[pasoActual],
          [name]: value,
        },
      }));
    } else {
      setFormularioValues((prevValues) => ({
        ...prevValues,
        [pasoActual]: {
          ...prevValues[pasoActual],
          [event.target.name]: event.target.value,
        },
      }));
    }
  };

  const handleChangeCheckboxHM = (event) => {      
    const { value } = event;    
    setCheckedItemsHM(event);
    handleCheckboxChange(event);
    setFormularioValues((prevValues) => ({
      ...prevValues,
      [pasoActual]: {
        ...prevValues[pasoActual],
        "p2m": value,
      },
    }));
  };

  const handleChangeCheckboxM = (event) => {
    const { value } = event;
    setCheckedItemsM(event);
    handleCheckboxChange(event);
    setFormularioValues((prevValues) => ({
      ...prevValues,
      [pasoActual]: {
        ...prevValues[pasoActual],
        "p2m": value,
      },
    }));
  };

  const isStepComplete = () => {
    if (step === 1) {
      return formularioValues[1]?.genero;
    } else if (step === 2) {
      return (
        formularioValues[1]?.u1 &&
        formularioValues[1]?.u2 &&
        formularioValues[1]?.u3 &&
        formularioValues[1]?.u4 &&
        formularioValues[1]?.u5 &&
        formularioValues[1]?.u6 &&
        formularioValues[1]?.u7 &&
        formularioValues[1]?.u8 &&
        formularioValues[1]?.u9 &&
        formularioValues[1]?.u10 &&
        formularioValues[1]?.u11
      );
    } else if (step === 3) {
      return (
        "formularioValues[1]?.p1h" &&
        "formularioValues[1]?.p2h" &&
        "formularioValues[1]?.p3h" &&
        "formularioValues[1]?.p1m" &&
        "formularioValues[1]?.p2m" &&
        "formularioValues[1]?.p3m"
      );
    } else if (step === 4) {
      return (
        formularioValues[1]?.p4 &&
        formularioValues[1]?.p5 &&
        formularioValues[1]?.p6 &&
        formularioValues[1]?.p8 &&
        formularioValues[1]?.p9
      );
    } else if (step === 5) {
      return (
        formularioValues[1]?.g1 &&
        formularioValues[1]?.g2 &&
        formularioValues[1]?.g3 &&
        formularioValues[1]?.g4 &&
        formularioValues[1]?.g5 &&
        formularioValues[1]?.g6
      );
    } else if (step === 6) {
      return (
        formularioValues[1]?.w1 &&
        "formularioValues[1]?.razones" &&
        formularioValues[1]?.w2 &&
        formularioValues[1]?.w3 &&
        "formularioValues[1]?.w4" &&
        "formularioValues[1]?.tipo_trabajo" &&
        "formularioValues[1]?.w5" &&
        "formularioValues[1]?.w6" &&
        "formularioValues[1]?.w7"
      );
    } else if (step === 7) {
      return (
        "formularioValues[1]?.s1" &&
        "formularioValues[1]?.s2" &&
        "formularioValues[1]?.motivo_interrupcion" &&
        "formularioValues[1]?.s3" &&
        "formularioValues[1]?.s4"
      );
    } else if (step === 8) {
      return (
        formularioValues[1]?.a1 &&
        formularioValues[1]?.a2 &&
        formularioValues[1]?.a3 &&
        formularioValues[1]?.a4 &&
        formularioValues[1]?.gq &&
        "formularioValues[1]?.aq"
      );
    }
    return false;
  };


  const handleOption2Change = (event) => { 
    const selectedValue = event.target.value;
    setSelectedOption2(selectedValue);
    // Habilitar o deshabilitar el Textarea del formluario 6 según la opción seleccionada
    setIsTextareaDisabled(selectedValue !== 'No trabajo por las siguientes razones:');    
  };

  const handleOption1Change = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption1(selectedValue);
    // Habilitar o deshabilitar el Select del formluario 7 según la opción seleccionada
    setIsSelectDisabled(selectedValue !== 'No (pase a la pregunta S2 y luego directamente a la sección siguiente)');    
  };

  const handleCheckboxChange = (newSelectedOptions) => {        
    setSelectedOptions(newSelectedOptions);
  };
  
  const goBack = () => {
    navigate(-1);
  };

  const handleFormSubmit = () => {
    // Se envian los datos a la api rest    
    if(formularioValues[1].genero === "mujer"){      
      createQuestionnaire({
        ...formularioValues,        
        p2m: checkedItemsM.join(", "),
        id_cirugia: idcirugia,
        tipo_cuestionario: pathname.includes("ussq") ? "ussq" : "eva",
      });
    } else {            
      createQuestionnaire({
        ...formularioValues,      
        p2h: checkedItemsHM.join(", "),
        id_cirugia: idcirugia,
        tipo_cuestionario: pathname.includes("ussq") ? "ussq" : "eva",
      });
    }
    
    // Se reinicia el formulario después de enviar los datos
    setFormularioValues({
      form1: {
        genero: "",
      },
      form2: {
        u1: "",
        u2: "",
        u3: "",
        u4: "",
        u5: "",
        u6: "",
        u7: "",
        u8: "",
        u9: "",
        u10: "",
        u11: "",
      },
      form3: {
        p1h: "",
        p2h: "",
        p3h: "",
        p1m: "",
        p2m: "",
        p3m: "",
      },
      form4: {
        p4: "",
        p5: "",
        p6: "",
        p7: "",
        p8: "",
        p9: "",
      },
      form5: {
        g1: "",
        g2: "",
        g3: "",
        g4: "",
        g5: "",
        g6: "",
      },
      form6: {
        w1: "",
        razones: "",
        w2: "",
        w3: "",
        w4: "",
        tipo_trabajo: "",
        w5: "",
        w6: "",
        w7: "",
      },
      form7: {
        s1: "",
        s2: "",
        motivo_interrupcion: "",
        s3: "",
        s4: "",
      },
      form8: {
        a1: "",
        a2: "",
        a3: "",
        a4: "",
        gq: "",
        aq: "",
      },
    });
    navigate(`/cirugia/${idcirugia}`);
    setPasoActual(1);
  };

  return (
    <>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        background="white"
        p={6}
        m="10px auto"
        as="form"
      >
        <Progress
          hasStripe
          value={progress}
          mb="5%"
          mx="5%"
          isAnimated
        ></Progress>
        <Flex justify={"flex-start"} align={"flex-end"} mb={4}>
          <ButtonWithIcon
            fn={goBack}
            titleIcon={FaReply}
            titleButton={"Volver"}
            colorScheme="cyan"
            color={"white"}
          />
        </Flex>
        {step === 1 ? (
          <Form1
            formularioValues={formularioValues}
            handleChange={handleChange}
            handleFormSubmitGender={handleFormSubmitGender}
            gender={gender}
          />
        ) : step === 2 ? (
          <Form2
            formularioValues={formularioValues}
            handleChange={handleChange}
          />
        ) : step === 3 ? (
          <Form3
            formularioValues={formularioValues}
            handleChange={handleChange}
            gender={gender}
            handleChangeCheckboxHM={handleChangeCheckboxHM}
            handleChangeCheckboxM={handleChangeCheckboxM}
            selectedOptions={selectedOptions}
            onCheckboxChange={handleCheckboxChange}
          />
        ) : step === 4 ? (
          <Form4
            formularioValues={formularioValues}
            handleChange={handleChange}
          />
        ) : step === 5 ? (
          <Form5
            formularioValues={formularioValues}
            handleChange={handleChange}
          />
        ) : step === 6 ? (
          <Form6
            formularioValues={formularioValues}
            handleChange={handleChange}            
            setSelectedOption2={setSelectedOption2}
            selectedOption2={selectedOption2}
            handleOption2Change={handleOption2Change}
            isTextareaDisabled={isTextareaDisabled}
          />
        ) : step === 7 ? (
          <Form7
            formularioValues={formularioValues}
            handleChange={handleChange}
            setSelectedOption1={setSelectedOption1}
            selectedOption1={selectedOption1}
            handleOption1Change={handleOption1Change}
            isSelectDisabled={isSelectDisabled}
          />
        ) : (
          <Form8
            formularioValues={formularioValues}
            handleChange={handleChange}
          />
        )}
        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent="space-between">
            <Flex>
              <Button
                onClick={() => {
                  setStep(step - 1);
                  setProgress(progress - 12.5);
                }}
                isDisabled={step === 1}
                colorScheme="teal"
                variant="solid"
                w="7rem"
                mr="5%"
              >
                Atrás
              </Button>
              <Button
                w="7rem"
                isDisabled={step === 8 || !isStepComplete()}
                onClick={() => {
                  setStep(step + 1);
                  if (step === 8) {
                    setProgress(100);
                  } else {
                    setProgress(progress + 12.5);
                  }
                }}
                colorScheme="teal"
                variant="outline"
              >
                Siguiente
              </Button>
            </Flex>
            {step === 8 ? (
              <Button
                isDisabled={!isStepComplete()}
                w="7rem"
                colorScheme="red"
                variant="solid"
                onClick={handleFormSubmit}
              >
                Enviar
              </Button>
            ) : null}
          </Flex>
        </ButtonGroup>
      </Box>
    </>
  );
};

const Form1 = ({ handleFormSubmitGender, gender }) => {
  return (
    <>
      <Heading
        w="100%"
        textAlign={"center"}
        fontWeight="normal"
        fontSize="lg"
        mb="2%"
      >
        URETERIC STENT SYMPTOMS QUESTIONNAIRE
      </Heading>
      <Heading
        w="100%"
        textAlign={"center"}
        fontWeight="normal"
        fontSize="lg"
        mb="8%"
      >
        Questionnaire 1 (Stent in situ)
      </Heading>
      <FormLabel mb="4%">
        Le ha sido insertado un catéter ureteral tipo doble JJ. Nos gustaría
        conocer su opinión sobre varios aspectos relacionados con su salud tras
        la inserción del catéter.
      </FormLabel>
      <FormLabel mb="4%">
        Por favor, complete las diferentes secciones del cuestionario que
        encontrará a continuación. Conteste todas las preguntas de cada sección.
      </FormLabel>
      <FormLabel mb="4%">
        (Le agradeceríamos que completara el cuestionario y nos los remitiera en
        un plazo de siete días)
      </FormLabel>

      <FormControl name="genero" mb="2%" isRequired>
        <FormLabel>Genero</FormLabel>
        <RadioGroup
          mb="2%"
          name="genero"
          value={gender}
          onChange={(value) =>
            handleFormSubmitGender({ target: { name: "genero", value }})
          }
        >
          <Stack direction="row" spacing={5}>
            <Radio value="hombre">Hombre</Radio>
            <Radio value="mujer">Mujer</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </>
  );
};

const Form2 = ({ handleChange, formularioValues }) => {
  return (
    <>
      <FormLabel mb="2%">
        En varias ocasiones se le pregunta si ha tenido síntomas ocasionalmente,
        algunas veces o la mayor parte del tiempo.
      </FormLabel>
      <FormLabel mb="2%">
        Ocasionalmente quiere decir menos de la tercera parte de las veces.
        Algunas veces quiere decir entre una y dos terceras partes de las veces.
        La mayoría del tiempo quiere decir más de las dos terceras partes de las
        veces.
      </FormLabel>
      <Heading
        w="100%"
        textAlign={"center"}
        fontWeight="bold"
        mb="2%"
        fontSize="lg"
      >
        SÍNTOMAS URINARIOS
      </Heading>
      <FormControl name="u1" mb="2%" isRequired>
        <FormLabel>U1. Durante el día ¿con qué frecuencia orina? </FormLabel>
        <Select
          name="u1"
          placeholder="Seleccionar"
          value={formularioValues[1]?.u1}
          onChange={handleChange}
        >
          <option value="Más de una vez en una hora">
            Más de una vez en una hora
          </option>
          <option value="Cada hora">Cada hora</option>
          <option value="Cada dos horas">Cada dos horas</option>
          <option value="Cada tres horas">Cada tres horas</option>
          <option value="Cada cuatro horas o más">
            Cada cuatro horas o más
          </option>
        </Select>
      </FormControl>

      <FormControl name="u2" mb="2%" isRequired>
        <FormLabel>
          U2 Durante la noche ¿con qué frecuencia ha de levantarse para orinar?
        </FormLabel>
        <Select
          name="u2"
          placeholder="Seleccionar"
          value={formularioValues[1]?.u2}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Una vez">Una vez</option>
          <option value="Dos veces">Dos veces</option>
          <option value="Tres veces">Tres veces</option>
          <option value="Cuatro veces o más">Cuatro veces o más</option>
        </Select>
      </FormControl>

      <FormControl name="u3" mb="2%" isRequired>
        <FormLabel>U3 ¿Tiene que correr al aseo para orinar? </FormLabel>
        <Select
          name="u3"
          placeholder="Seleccionar"
          value={formularioValues[1]?.u3}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de las dos terceras partes de las veces)">
            La mayoría de las veces (más de las dos terceras partes de las
            veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="u4" mb="2%" isRequired>
        <FormLabel>
          U4 ¿Tiene usted pérdidas de orina antes de llegar al aseo?
        </FormLabel>
        <Select
          name="u4"
          placeholder="Seleccionar"
          value={formularioValues[1]?.u4}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de las dos terceras partes de las veces)">
            La mayoría de las veces (más de las dos terceras partes de las
            veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="u5" mb="2%" isRequired>
        <FormLabel>
          U5 ¿Se le escapa la orina sin advertir la necesidad de ir al aseo?
        </FormLabel>
        <Select
          name="u5"
          placeholder="Seleccionar"
          value={formularioValues[1]?.u5}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de las dos terceras partes de las veces)">
            La mayoría de las veces (más de las dos terceras partes de las
            veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="u6" mb="2%" isRequired>
        <FormLabel>
          U6 ¿Con qué frecuencia tiene la sensación de que la vejiga no se ha
          vaciado completamente tras haber orinado?
        </FormLabel>
        <Select
          name="u6"
          placeholder="Seleccionar"
          value={formularioValues[1]?.u6}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de las dos terceras partes de las veces)">
            La mayoría de las veces (más de las dos terceras partes de las
            veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="u7" mb="2%" isRequired>
        <FormLabel>U7 ¿Tiene sensación de escozor al orinar? </FormLabel>
        <Select
          name="u7"
          placeholder="Seleccionar"
          value={formularioValues[1]?.u7}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de las dos terceras partes de las veces)">
            La mayoría de las veces (más de las dos terceras partes de las
            veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="u8" mb="2%" isRequired>
        <FormLabel>
          U8 ¿Cuántas veces ha observado sangre en la orina?
        </FormLabel>
        <Select
          name="u8"
          placeholder="Seleccionar"
          value={formularioValues[1]?.u8}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de las dos terceras partes de las veces)">
            La mayoría de las veces (más de las dos terceras partes de las
            veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="u9" mb="2%" isRequired>
        <FormLabel>U9 ¿Cuánta sangre ha notado en la orina? </FormLabel>
        <Select
          name="u9"
          placeholder="Seleccionar"
          value={formularioValues[1]?.u9}
          onChange={handleChange}
        >
          <option value="Nunca vi sangre">Nunca vi sangre</option>
          <option value="Orina levemente manchada de sangre">
            Orina levemente manchada de sangre
          </option>
          <option value="Orina intensamente manchada de sangre">
            Orina intensamente manchada de sangre
          </option>
          <option value="Orina intensamente manchada de sangre y con coágulos">
            Orina intensamente manchada de sangre y con coágulos
          </option>
        </Select>
      </FormControl>

      <FormControl name="u10" mb="2%" isRequired>
        <FormLabel>
          U10 En general, ¿en qué medida sus síntomas urinarios constituyen un
          problema para usted?
        </FormLabel>
        <Select
          name="u10"
          placeholder="Seleccionar"
          value={formularioValues[1]?.u10}
          onChange={handleChange}
        >
          <option value="Nada">Nada</option>
          <option value="Poco">Poco</option>
          <option value="Moderadamente">Moderadamente</option>
          <option value="Bastante">Bastante</option>
          <option value="Mucho">Mucho</option>
        </Select>
      </FormControl>

      <FormControl name="u11" mb="2%" isRequired>
        <FormLabel>
          U11 Si tuviera que pasar el resto de su vida con estos síntomas
          asociados al catéter, tal y como está ahora ¿cómo se sentiría?
        </FormLabel>
        <Select
          name="u11"
          placeholder="Seleccionar"
          value={formularioValues[1]?.u11}
          onChange={handleChange}
        >
          <option value="Muy contento">Muy contento</option>
          <option value="Contento">Contento</option>
          <option value="Bastante satisfecho">Bastante satisfecho</option>
          <option value="Indiferente">Indiferente</option>
          <option value="Bastante insatisfecho">Bastante insatisfecho</option>
          <option value="Descontento">Descontento</option>
          <option value="Muy descontento">Muy descontento</option>
        </Select>
      </FormControl>
    </>
  );
};

const Form3 = ({
  handleChange,
  gender,
  formularioValues,
  handleChangeCheckboxHM,
  handleChangeCheckboxM,
  selectedOptions,
  onCheckboxChange
}) => {
  return (
    <>
      {gender === "hombre" ? (
        <Box>
          <Heading
            w="100%"
            textAlign={"center"}
            fontWeight="bold"
            mb="2%"
            fontSize="lg"
          >
            DOLOR CORPORAL (PARA HOMBRES)
          </Heading>
          <FormLabel mb="2%">
            En esta sección encontrará preguntas relacionadas con el dolor o las
            molestias que usted asocia a la colocación del catéter ureteral.
          </FormLabel>
          <FormLabel mb="2%">
            Por favor, piense en su experiencia tras la inserción del catéter.
          </FormLabel>

          <FormControl name="p1h" mb="2%" isRequired>
            <FormLabel>
              P1 ¿Ha padecido algún tipo de dolor o molestia asociados a la
              presencia del catéter ureteral?
            </FormLabel>
            <Select
              name="p1h"
              placeholder="Seleccionar"
              value={formularioValues[1]?.p1h}
              onChange={handleChange}
            >
              <option value="Sí (pase a la pregunta P2)">
                Sí (pase a la pregunta P2)
              </option>
              <option value="No (pase a la sección siguiente, ignorando las preguntas de P2 a P9)">
                No (pase a la sección siguiente, ignorando las preguntas de P2 a
                P9)
              </option>
            </Select>
          </FormControl>

          <FormControl name="p2h" mb="2%" isRequired>
            <FormLabel mb="2%">
              P2 Imagine que el dibujo que encontrará a continuación es la
              representación de su cuerpo. Marque con una X o coloree el
              lugar(es) donde usted siente dolor o molestias asociados al
              catéter (por ejemplo, durante las actividades cotidianas o cada
              vez que orina):
            </FormLabel>
            <Box mb="2%">
              <Image src={imghombre} alt="Dan Abramov" />
            </Box>

            <CheckboxGroup
              colorScheme="green"
              onChange={handleChangeCheckboxHM}                            
              value={selectedOptions}
            >
              <Stack spacing={[1, 5]} direction={["column", "row"]}>
                <Checkbox value="A: Región renal frontal / lateral">
                  A: Región renal frontal / lateral
                </Checkbox>
                <Checkbox value="B: Región inguinal">
                  B: Región inguinal
                </Checkbox>
                <Checkbox value="C: Región vesical">C: Región vesical</Checkbox>
                <Checkbox value="D: Pene">
                  D: Pene)
                </Checkbox>
                <Checkbox value="E: Región renal lumbar (zona de la espalda)">
                  E: Región renal lumbar (zona de la espalda)
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>

          <FormLabel mb="6%">
            En caso de padecer dolor en más de un punto, señale la localización
            de cada uno.
          </FormLabel>

          <FormControl name="p3h" mb="2%" isRequired>
            <FormLabel mb="4%">
              P3 En una escala de 0 a 10, marque con una X el punto que indica
              la intensidad de su dolor o molestia asociados al catéter. Si ha
              tenido dolor o molestias en más de un lugar, ponga tantas marcas
              como sean necesarias y escriba junto a ellas la letra
              correspondiente a cada localización.
            </FormLabel>

            <TableContainer>
              <Table variant="simple" mb="4%">
                <Thead>
                  <Tr>
                    <Th>No siente dolor o malestar</Th>
                    <Th>0 1 2 3 4 5 6 7 8 9 10</Th>
                    <Th>Dolor muy intenso</Th>
                  </Tr>
                </Thead>
              </Table>
            </TableContainer>

            <NumberInput
              name="p3h"
              step={1}
              defaultValue={0}
              value={formularioValues[1]?.p3h}
              min={0}
              max={10}
              onChange={(value) =>
                handleChange({
                  target: { name: "p3h", value },
                })
              }
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Box>
      ) : (
        <Box>
          <Heading
            w="100%"
            textAlign={"center"}
            fontWeight="bold"
            mb="2%"
            fontSize="lg"
          >
            DOLOR CORPORAL (PARA MUJERES)
          </Heading>
          <FormLabel mb="2%">
            En esta sección encontrará preguntas relacionadas con el dolor o las
            molestias que usted asocia a la colocación del catéter ureteral.
          </FormLabel>
          <FormLabel mb="2%">
            Por favor, piense en su experiencia tras la inserción del catéter.
          </FormLabel>

          <FormControl name="p1m" mb="2%" isRequired>
            <FormLabel>
              P1 ¿Ha padecido algún tipo de dolor o molestia asociados a la
              presencia del catéter ureteral?
            </FormLabel>
            <Select
              name="p1m"
              placeholder="Seleccionar"
              value={formularioValues[1]?.p1m}
              onChange={handleChange}
            >
              <option value="Sí (pase a la pregunta P2)">
                Sí (pase a la pregunta P2)
              </option>
              <option value="No (pase a la sección siguiente, ignorando las preguntas de P2 a P9)">
                No (pase a la sección siguiente, ignorando las preguntas de P2 a
                P9)
              </option>
            </Select>
          </FormControl>

          <FormControl name="p2m" mb="2%" isRequired>
            <FormLabel mb="2%">
              P2 Imagine que el dibujo que encontrará a continuación es la
              representación de su cuerpo. Marque con una X o coloree el
              lugar(es) donde usted siente dolor o molestias asociados al
              catéter (por ejemplo, durante las actividades cotidianas o cada
              vez que orina):
            </FormLabel>
            <Box mb="2%">
              <Image src={imgmujer} alt="Dan Abramov" />
            </Box>

            <CheckboxGroup
              colorScheme="green"
              onChange={handleChangeCheckboxM}                            
              value={selectedOptions}
            >
              <Stack spacing={[1, 5]} direction={["column", "row"]}>
                <Checkbox value="A: Región renal frontal / lateral">
                  A: Región renal frontal / lateral
                </Checkbox>
                <Checkbox value="B: Región inguinal">
                  B: Región inguinal
                </Checkbox>
                <Checkbox value="C: Región vesical">C: Región vesical</Checkbox>
                <Checkbox value="D: Región renal lumbar (zona de la espalda)">
                  D: Región renal lumbar (zona de la espalda)
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>

          <FormLabel mb="6%">
            En caso de padecer dolor en más de un punto, señale la localización
            de cada uno.
          </FormLabel>

          <FormControl name="p3m" mb="2%" isRequired>
            <FormLabel mb="2%">
              P3 En una escala de 0 a 10, marque con una X el punto que indica
              la intensidad de su dolor o molestia asociados al catéter. Si ha
              tenido dolor o molestias en más de un lugar, ponga tantas marcas
              como sean necesarias y escriba junto a ellas la letra
              correspondiente a cada localización.
            </FormLabel>

            <TableContainer>
              <Table variant="simple" mb="4%">
                <Thead>
                  <Tr>
                    <Th>No siente dolor o malestar</Th>
                    <Th>0 1 2 3 4 5 6 7 8 9 10</Th>
                    <Th>Dolor muy intenso</Th>
                  </Tr>
                </Thead>
              </Table>
            </TableContainer>

            <NumberInput
              name="p3m"
              step={1}
              defaultValue={0}
              value={formularioValues[1]?.p3m}
              min={0}
              max={10}
              onChange={(value) =>
                handleChange({
                  target: { name: "p3m", value },
                })
              }
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Box>
      )}
    </>
  );
};

const Form4 = ({ handleChange, formularioValues }) => {
  return (
    <>
      <FormControl name="p4" mb="2%" isRequired>
        <FormLabel>
          P4 ¿Cuál de las siguientes afirmaciones describe mejor su situación
          respecto a la actividad física y al dolor o molestias relacionados con
          la presencia del catéter?
        </FormLabel>
        <Select
          name="p4"
          placeholder="Seleccionar"
          value={formularioValues[1]?.p4}
          onChange={handleChange}
        >
          <option value="No padezco ningún dolor o molestia">
            No padezco ningún dolor o molestia
          </option>
          <option
            value="Padezco dolor o molestia sólo cuando realizo una actividad física
            intensa (por ejemplo, practicar deportes intensos o levantar objetos
            pesados)"
          >
            Padezco dolor o molestia sólo cuando realizo una actividad física
            intensa (por ejemplo, practicar deportes intensos o levantar objetos
            pesados)
          </option>
          <option
            value="Padezco dolor o molestia al realizar actividades de moderada
            intensidad pero no en las actividades sencillas (por ejemplo, caminar
            algunos cientos de metros o conducir el coche) "
          >
            Padezco dolor o molestia al realizar actividades de moderada
            intensidad pero no en las actividades sencillas (por ejemplo,
            caminar algunos cientos de metros o conducir el coche)
          </option>
          <option
            value="Padezco dolor incluso cuando realizo actividades básicas de la vida
            cotidiana (por ejemplo, andar por casa o vestirse)"
          >
            Padezco dolor incluso cuando realizo actividades básicas de la vida
            cotidiana (por ejemplo, andar por casa o vestirse)
          </option>
          <option value="Padezco dolor en reposo">
            Padezco dolor en reposo
          </option>
        </Select>
      </FormControl>

      <FormControl name="p5" mb="2%" isRequired>
        <FormLabel mb="2%">
          P5 ¿Se despierta por la noche por un dolor o molestia asociado al
          catéter?
        </FormLabel>
        <Select
          name="p5"
          placeholder="Seleccionar"
          value={formularioValues[1]?.p5}
          onChange={handleChange}
        >
          <option value="Nunca ">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de dos terceras partes de las veces)">
            La mayoría de las veces (más de dos terceras partes de las veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="p6" mb="2%" isRequired>
        <FormLabel mb="2%">
          P6 ¿Padece dolor o molestia asociado al catéter durante la micción?
        </FormLabel>
        <Select
          name="p6"
          placeholder="Seleccionar"
          value={formularioValues[1]?.p6}
          onChange={handleChange}
        >
          <option value="Nunca ">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de dos terceras partes de las veces)">
            La mayoría de las veces (más de dos terceras partes de las veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="p7" mb="2%" isRequired>
        <FormLabel mb="2%">
          P7 ¿Padece dolor o molestia en la zona del riñón durante la micción?
        </FormLabel>
        <Select
          name="p7"
          placeholder="Seleccionar"
          value={formularioValues[1]?.p7}
          onChange={handleChange}
        >
          <option value="No ">No</option>
          <option value="Si">Si</option>
        </Select>
      </FormControl>

      <FormControl name="8" mb="2%" isRequired>
        <FormLabel mb="2%">
          P8 ¿Cuántas veces ha necesitado tomar analgésicos (P8) para controlar
          el dolor o las molestias asociados al catéter?
        </FormLabel>
        <Select
          name="p8"
          placeholder="Seleccionar"
          value={formularioValues[1]?.p8}
          onChange={handleChange}
        >
          <option value="Nunca ">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de dos terceras partes de las veces)">
            La mayoría de las veces (más de dos terceras partes de las veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="p9" mb="2%" isRequired>
        <FormLabel mb="2%">
          P9 En general ¿en qué grado le afecta a su vida diaria el dolor o
          molestia asociados al catéter?
        </FormLabel>
        <Select
          name="p9"
          placeholder="Seleccionar"
          value={formularioValues[1]?.p9}
          onChange={handleChange}
        >
          <option value="Nada ">Nada</option>
          <option value="Poco">Poco</option>
          <option value="Moderadamente">Moderadamente</option>
          <option value="Bastante">Bastante</option>
          <option value="Mucho">Mucho</option>
        </Select>
      </FormControl>
    </>
  );
};

const Form5 = ({ handleChange, formularioValues }) => {
  return (
    <>
      <Heading
        w="100%"
        textAlign={"center"}
        fontWeight="bold"
        mb="2%"
        fontSize="lg"
      >
        ESTADO GENERAL DE SALUD
      </Heading>
      <FormLabel mb="2%">
        Esta sección investiga su estado general de salud tras la colocación del
        catéter ureteral.
      </FormLabel>

      <FormControl name="g1" mb="2%" isRequired>
        <FormLabel>
          G1 ¿Ha tenido alguna dificultad para realizar actividades físicas
          ligeras (por ejemplo, caminar distancias cortas o conducir el coche)?
        </FormLabel>
        <Select
          name="g1"
          placeholder="Seleccionar"
          value={formularioValues[1]?.g1}
          onChange={handleChange}
        >
          <option value="Por lo general sin ninguna dificultad">
            Por lo general sin ninguna dificultad
          </option>
          <option value="Generalmente con algunas dificultades">
            Generalmente con algunas dificultades
          </option>
          <option value="Generalmente con muchas dificultades">
            Generalmente con muchas dificultades
          </option>
          <option value="Generalmente no las hago a causa del catéter">
            Generalmente no las hago a causa del catéter
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="g2" mb="2%" isRequired>
        <FormLabel>
          G2 ¿Ha tenido alguna dificultad para realizar actividades físicas
          intensas (por ejemplo, deportes intensos o levantar objetos pesados)?
        </FormLabel>
        <Select
          name="g2"
          placeholder="Seleccionar"
          value={formularioValues[1]?.g2}
          onChange={handleChange}
        >
          <option value="Por lo general sin ninguna dificultad">
            Por lo general sin ninguna dificultad
          </option>
          <option value="Generalmente con algunas dificultades">
            Generalmente con algunas dificultades
          </option>
          <option value="Generalmente con muchas dificultades">
            Generalmente con muchas dificultades
          </option>
          <option value="Generalmente no las hago a causa del catéter">
            Generalmente no las hago a causa del catéter
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="g3" mb="2%" isRequired>
        <FormLabel mb="2%">
          G3 ¿Se ha sentido cansado y sin forma física?
        </FormLabel>
        <Select
          name="g3"
          placeholder="Seleccionar"
          value={formularioValues[1]?.g3}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de dos terceras partes de las veces)">
            La mayoría de las veces (más de dos terceras partes de las veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="g4" mb="2%" isRequired>
        <FormLabel mb="2%">G4 ¿Se ha sentido calmado y tranquilo?</FormLabel>
        <Select
          name="g4"
          placeholder="Seleccionar"
          value={formularioValues[1]?.g4}
          onChange={handleChange}
        >
          <option value="Siempre ">Siempre</option>
          <option value="La mayoría de las veces (más de dos terceras partes del tiempo)">
            La mayoría de las veces (más de dos terceras partes del tiempo)
          </option>
          <option value="A veces (entre una y dos terceras partes del tiempo)">
            A veces (entre una y dos terceras partes del tiempo)
          </option>
          <option value="Ocasionalmente (menos de una tercera parte del tiempo)">
            Ocasionalmente (menos de una tercera parte del tiempo)
          </option>
          <option value="Nunca">Nunca</option>
        </Select>
      </FormControl>

      <FormControl name="g5" mb="2%" isRequired>
        <FormLabel mb="2%">
          G5 ¿Ha disfrutado de su vida social (por ejemplo, salir fuera, invitar
          amigos a casa, etc)?
        </FormLabel>
        <Select
          name="g5"
          placeholder="Seleccionar"
          value={formularioValues[1]?.g5}
          onChange={handleChange}
        >
          <option value="Siempre ">Siempre</option>
          <option value="La mayoría de las veces (más de dos terceras partes de las veces)">
            La mayoría de las veces (más de dos terceras partes de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="Nunca">Nunca</option>
        </Select>
      </FormControl>

      <FormControl name="g6" mb="2%" isRequired>
        <FormLabel mb="2%">
          G6 ¿Ha necesitado más ayuda de lo normal por parte de sus familiares o
          amigos?
        </FormLabel>
        <Select
          name="g6"
          placeholder="Seleccionar"
          value={formularioValues[1]?.g6}
          onChange={handleChange}
        >
          <option value="Nunca ">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de dos terceras partes de las veces)">
            La mayoría de las veces (más de dos terceras partes de las veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>
    </>
  );
};

const Form6 = ({
  handleChange,
  formularioValues,  
  selectedOption2,
  isTextareaDisabled,
  handleOption2Change
}) => {
  return (
    <>
      <Heading
        w="100%"
        textAlign={"center"}
        fontWeight="bold"
        mb="2%"
        fontSize="lg"
      >
        ACTIVIDAD LABORAL
      </Heading>

      <FormControl name="w1" onChange={handleChange} mb="2%" isRequired>
        <FormLabel>W1 Especifique su situación laboral</FormLabel>
        <RadioGroup
          mb="2%"
          name="w1"
          value={selectedOption2}
          onChange={(value) =>
            handleOption2Change({ target: { name: "w1", value } })            
          }
        >
          <Stack direction="column" spacing={5}>
            <Radio value="Trabajo a tiempo completo">
              Trabajo a tiempo completo
            </Radio>
            <Radio value=" Trabajo a tiempo parcial">
              Trabajo a tiempo parcial
            </Radio>
            <Radio value="Estudiante">Estudiante</Radio>
            <Radio value="En el paro o en búsqueda de trabajo">
              En el paro o en búsqueda de trabajo
            </Radio>
            <Radio value="Jubilado por otras razones (incluido por edad)">
              Jubilado por otras razones (incluido por edad)
            </Radio>
            <Radio value="No trabajo por las siguientes razones:">
              No trabajo por las siguientes razones:
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl mt={1} name="razones">        
        <Textarea
          placeholder="Especificar cuáles"
          size="sm"
          mb="2%"
          onChange={handleChange}
          name="razones"
          value={formularioValues[1]?.razones}
          isDisabled={isTextareaDisabled}          
        />
      </FormControl>

      <FormControl name="w2" mb="2%" isRequired>
        <FormLabel mb="2%">
          W2 Tras la colocación del catéter, ¿cuántos días ha tenido que
          quedarse en cama durante todo el día o buena parte de éste por los
          síntomas asociados al catéter? Nº días…
        </FormLabel>

        <NumberInput
          name="w2"
          step={1}
          defaultValue={0}
          value={formularioValues[1]?.w2}
          min={0}
          max={365}
          onChange={(value) => handleChange({ target: { name: "w2", value } })}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <FormControl name="w3" mb="2%" isRequired>
        <FormLabel mb="2%">
          W3 Tras la colocación del catéter, durante cuántos días tuvo que
          reducir sus actividades cotidianas a causa de los síntomas? Nº días…
        </FormLabel>

        <NumberInput
          name="w3"
          step={1}
          defaultValue={0}
          value={formularioValues[1]?.w3}
          min={0}
          max={365}
          onChange={(value) =>
            handleChange({
              target: { name: "w3", value },
            })
          }
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <FormControl name="w4" mb="2%">
        <FormLabel mb="2%">
          a) Puesto profesional y descripción de su empleo:
        </FormLabel>
        <Input
          name="w4"
          value={formularioValues[1]?.w4}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl name="tipo_trabajo" mb="2%">
        <FormLabel mb="2%">b) Usted es:</FormLabel>
        <Select
          name="tipo_trabajo"
          placeholder="Seleccionar"
          value={formularioValues[1]?.tipo_trabajo}
          onChange={handleChange}
        >
          <option value="Empleado">Empleado</option>
          <option value="Empresario">Empresario</option>
          <option value="Autónomo">Autónomo</option>
        </Select>
      </FormControl>

      <FormLabel mb="2%">
        Conteste a las siguientes preguntas sólo si ha trabajado después de la
        colocación del catéter
      </FormLabel>

      <FormControl name="w5" mb="2%">
        <FormLabel>
          W5 ¿Ha trabajado por periodos breves o ha necesitado días de baja
          laboral a causa de los síntomas asociados al catéter?
        </FormLabel>
        <Select
          name="w5"
          placeholder="Seleccionar"
          value={formularioValues[1]?.w5}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de dos terceras partes de las veces)">
            La mayoría de las veces (más de dos terceras partes de las veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="w6" mb="2%">
        <FormLabel>
          W6 ¿Ha necesitado realizar cambios en su empleo habitual debido a los
          síntomas relacionados con el catéter?
        </FormLabel>
        <Select
          name="w6"
          placeholder="Seleccionar"
          value={formularioValues[1]?.w6}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de dos terceras partes de las veces)">
            La mayoría de las veces (más de dos terceras partes de las veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="w7" mb="2%">
        <FormLabel>W7 ¿Ha trabajado el número de horas habitual?</FormLabel>
        <Select
          name="w7"
          placeholder="Seleccionar"
          value={formularioValues[1]?.w7}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Ocasionalmente (menos de una tercera parte de las veces)">
            Ocasionalmente (menos de una tercera parte de las veces)
          </option>
          <option value="A veces (entre una y dos terceras partes de las veces)">
            A veces (entre una y dos terceras partes de las veces)
          </option>
          <option value="La mayoría de las veces (más de dos terceras partes de las veces)">
            La mayoría de las veces (más de dos terceras partes de las veces)
          </option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>
    </>
  );
};

const Form7 = ({
  handleChange,
  formularioValues,  
  selectedOption1,
  handleOption1Change,
  isSelectDisabled
}) => {  
  return (
    <>
      <Heading
        w="100%"
        textAlign={"center"}
        fontWeight="bold"
        mb="2%"
        fontSize="lg"
      >
        PROBLEMAS SEXUALES
      </Heading>
      <FormLabel mb="4%">
        Por favor, marque una única casilla para cada pregunta teniendo en
        cuenta su experiencia tras la inserción del catéter.
      </FormLabel>

      <FormControl name="s1" mb="2%" onChange={handleChange} isRequired>
        <FormLabel>
          S1 Actualmente, ¿tiene usted una vida sexual activa?
        </FormLabel>
        <Select
          name="s1"
          placeholder="Seleccionar"
          value={selectedOption1}
          onChange={handleOption1Change}
        >
          <option value="No (pase a la pregunta S2 y luego directamente a la sección siguiente)">
            No (pase a la pregunta S2 y luego directamente a la sección
            siguiente)
          </option>
          <option value="Sí (pase a la pregunta S3)">
            Sí (pase a la pregunta S3)
          </option>
        </Select>
      </FormControl>

      <FormControl name="s2" mb="2%">
        <FormLabel>
          S2 Si no tiene una vida sexual activa, ¿desde cuándo le ocurre?
        </FormLabel>
        <Select
          name="s2"
          placeholder="Seleccionar"
          value={formularioValues[1]?.s2}
          onChange={handleChange}
          isDisabled={isSelectDisabled}
        >
          <option value="Desde la colocación del catéter ureteral">
            Desde la colocación del catéter ureteral
          </option>
          <option value="Desde antes de la colocación del catéter ureteral">
            Desde antes de la colocación del catéter ureteral
          </option>
        </Select>
      </FormControl>

      <FormControl
        name="motivo_interrupcion"
        mb="2%"
        isDisabled={isSelectDisabled}
      >
        <FormLabel>¿Y por qué motivo se interrumpió?</FormLabel>
        <Select
          name="motivo_interrupcion"
          placeholder="Seleccionar"
          value={formularioValues[1]?.motivo_interrupcion}
          onChange={handleChange}
        >
          <option value="Por problemas asociados al catéter ureteral">
            Por problemas asociados al catéter ureteral
          </option>
          <option value="No he intentado tener actividad sexual">
            No he intentado tener actividad sexual
          </option>
          <option value="Por otras razones no relacionadas con el catéter">
            Por otras razones no relacionadas con el catéter
          </option>
        </Select>
      </FormControl>

      <FormLabel mb="4%">
        PorConteste a las preguntas S3 y S4 sólo si ha contestado “sí” a la
        pregunta S1. Estas preguntas hacen referencia a su vida sexual después
        de la colocación del catéter.
      </FormLabel>

      <FormControl name="s3" mb="2%">
        <FormLabel mb="2%">
          S3 ¿Ha padecido algún tipo de dolor mientras mantenía relaciones
          sexuales? En caso afirmativo, ¿de qué tipo?
        </FormLabel>
        <Select
          name="s3"
          placeholder="Seleccionar"
          value={formularioValues[1]?.s3}
          onChange={handleChange}
        >
          <option value="No">No</option>
          <option value="Leve">Leve</option>
          <option value="Moderado">Moderado</option>
          <option value="Fuerte">Fuerte</option>
          <option value="Muy fuerte">Muy fuerte</option>
        </Select>
      </FormControl>

      <FormControl name="s4" mb="2%">
        <FormLabel>S4 ¿Está satisfecho con su vida sexual?</FormLabel>
        <Select
          name="s4"
          placeholder="Seleccionar"
          value={formularioValues[1]?.s4}
          onChange={handleChange}
        >
          <option value="Muy satisfecho">Muy satisfecho</option>
          <option value="Satisfecho">Satisfecho</option>
          <option value="No del todo satisfecho ">
            No del todo satisfecho
          </option>
          <option value="Insatisfecho">Insatisfecho</option>
          <option value="Muy insatisfecho">Muy insatisfecho</option>
        </Select>
      </FormControl>
    </>
  );
};

const Form8 = ({ handleChange, formularioValues }) => {
  return (
    <>
      <Heading
        w="100%"
        textAlign={"center"}
        fontWeight="bold"
        mb="2%"
        fontSize="lg"
      >
        OTRAS MOLESTIAS
      </Heading>
      <FormLabel mb="4%">
        Las preguntas que hallará a continuación se centran en su experiencia
        tras la colocación del catéter ureteral. Por favor, indique su
        experiencia marcando con una X la casilla correspondiente.
      </FormLabel>

      <FormControl name="a1" mb="2%" isRequired>
        <FormLabel>
          A1 ¿Con qué frecuencia ha creído que estaba padeciendo una infección
          de orina (fiebre continua, malestar general o dolor durante la
          micción)?
        </FormLabel>
        <Select
          name="a1"
          placeholder="Seleccionar"
          value={formularioValues[1]?.a1}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="Ocasionalmente">Ocasionalmente</option>
          <option value="A veces">A veces</option>
          <option value="A menudo">A menudo</option>
          <option value="Siempre">Siempre</option>
        </Select>
      </FormControl>

      <FormControl name="a2" mb="2%" isRequired>
        <FormLabel>
          A2 ¿Ha necesitado tomar antibióticos a causa de la colocación del
          catéter (no incluya los antibióticos preventivos que ha tomado en el
          momento de la colocación del catéter).
        </FormLabel>
        <Select
          name="a2"
          placeholder="Seleccionar"
          value={formularioValues[1]?.a2}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="1 tanda">1 tanda</option>
          <option value="2 tandas">2 tandas</option>
          <option value="3 tandas o más">3 tandas o más</option>
        </Select>
      </FormControl>

      <FormControl name="a3" mb="2%" isRequired>
        <FormLabel>
          A3 ¿Ha necesitado de la ayuda de su médico de atención primaria o de
          un enfermero por algún problema relacionado con el catéter?
        </FormLabel>
        <Select
          name="a3"
          placeholder="Seleccionar"
          value={formularioValues[1]?.a3}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="1 vez">1 vez</option>
          <option value="2 veces">2 veces</option>
          <option value="3 veces o más">3 veces o más</option>
        </Select>
      </FormControl>

      <FormControl name="a4" mb="2%" isRequired>
        <FormLabel mb="2%">
          A4 ¿Ha necesitado acudir al hospital por algún problema relacionado
          con el catéter?
        </FormLabel>
        <Select
          name="a4"
          placeholder="Seleccionar"
          value={formularioValues[1]?.a4}
          onChange={handleChange}
        >
          <option value="Nunca">Nunca</option>
          <option value="1 vez">1 vez</option>
          <option value="2 veces">2 veces</option>
          <option value="Fuerte">Fuerte</option>
          <option value="3 veces o más">3 veces o más</option>
        </Select>
      </FormControl>

      <FormControl name="gq" mb="2%" isRequired>
        <FormLabel>
          GQ En el futuro, si tuviera que llevar de nuevo otro catéter ureteral,
          ¿cómo se sentiría?
        </FormLabel>
        <Select
          name="gq"
          placeholder="Seleccionar"
          value={formularioValues[1]?.gq}
          onChange={handleChange}
        >
          <option value="Muy contento">Muy contento</option>
          <option value="Contento">Contento</option>
          <option value="Bastante satisfecho ">Bastante satisfecho </option>
          <option value="Bastante insatisfecho">Bastante insatisfecho</option>
          <option value="Muy descontento">Muy descontento</option>
        </Select>
      </FormControl>

      <FormControl mt={1} name="aq">
        <FormLabel>
          AQ Si desea hacer algún comentario acerca del cuestionario o de alguno
          de sus síntomas, puede usar el siguiente espacio:
        </FormLabel>
        <Textarea
          rows={3}
          shadow="sm"
          focusBorderColor="brand.400"
          fontSize={{
            sm: "sm",
          }}
          name="aq"
          onChange={handleChange}
          placeholder="Comentarios"
          value={formularioValues[1]?.aq}
        />
      </FormControl>
    </>
  );
};
