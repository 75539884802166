/* 
    maneja todas las funciones del Professional context que engloba todas las acciones asociadas al profesional
*/

import React, { useContext, useReducer } from "react";
import { useToast } from "@chakra-ui/react";
import { ProfessionalContext } from "./ProfessionalContext";
import { ProfessionalReducer } from "./ProfessionalReducer";
import { API } from "../../api/api";
import AuthContext from "../auth/AuthContext";

export const ProfessionalState = ({ children }) => {
  const toast = useToast();
  const { usuario } = useContext(AuthContext);

  const initialState = {
    professionals: [],
    professional: {},
    feedbacks: [],
    feedback: {},
  };

  const [state, dispatch] = useReducer(ProfessionalReducer, initialState);

  const getsProfessionals = async () => {
    const endpoint = "/profesional";
    try {
      const { data } = await API.get(endpoint);

      dispatch({
        type: "GET_PROFESSIONALS",
        payload: data.profesionales,
      });
    } catch (error) {
      handleError(error, "Obtener profesionales");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getProfessional = async (idprofesional) => {
    const endpoint = `/profesional/${idprofesional}`;
    try {
      const { data } = await API.get(endpoint);

      dispatch({
        type: "GET_PROFESSIONAL",
        payload: data.item,
      });
    } catch (error) {
      handleError(error, "Obtener profesional");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const createProfessional = async (data) => {
    const endpoint = "/profesional/nuevo-profesional";
    try {
      await API.post(endpoint, data);
      await getsProfessionals();

      toast({
        description: "Profesional creado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error, "Crear profesional");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const updateProfessional = async (data) => {
    const { id } = data;
    const endpoint = `/profesional/editar-profesional/${id}`;

    try {
      await API.put(endpoint, { ...data });
      await getProfessional(id);
      await getsProfessionals();

      toast({
        description: "Usuario actualizado.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error, "Actualizar profesional");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  /* *************************************** 
    METODOS DE FEEDBACKS
  ****************************************** */

  const createFeedback = async (data) => {
    const endpoint = "/feedback";

    try {
      await API.post(endpoint, data);

      toast({
        description: "Feedback creado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error, "Crear feedback");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getsFeedbacks = async () => {
    const endpoint = "/feedback";
    try {
      const { data } = await API.get(endpoint);

      dispatch({ type: "GETS_FEEDBACKS", payload: data.feedbacks });
    } catch (error) {
      handleError(error, "Obtener feedbacks");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleError = async (error, process) => {
    try {
      const bodyLog = {
        correo: usuario.email,
        codigo: error.response.status,
        proceso: process,
        mensaje: error.response.data.message,
        respuesta: error,
      };
      await API.post("/logs", bodyLog);
      console.log(error);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ProfessionalContext.Provider
      value={{
        professionals: state.professionals,
        professional: state.professional,
        feedbacks: state.feedbacks,
        feedback: state.feedback,
        getsProfessionals,
        getProfessional,
        createProfessional,
        updateProfessional,
        createFeedback,
        getsFeedbacks,
      }}
    >
      {children}
    </ProfessionalContext.Provider>
  );
};
