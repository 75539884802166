/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  IconButton,
  ScaleFade,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { RenderLoading } from "../components/RenderAlert";
import { SurgeryContext } from "../context/surgery/SurgeryContext";
import { FaSearch } from "react-icons/fa";
import AuthContext from "../context/auth/AuthContext";
import { formatDate } from "../utils/formatDate";
import { useNavigate } from "react-router-dom";

export const SurgeriesScreen = () => {
  const navigate = useNavigate();
  const { usuario } = useContext(AuthContext);
  const { getsSurgeriesByPatient, surgeries } = useContext(SurgeryContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        setIsLoading(true);
        await getsSurgeriesByPatient(usuario.id_paciente);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPatients();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            shadow={"md"}
            rounded={"md"}
            maxW={"full"}
            py={8}
          >
            <TableContainer borderRadius={10} shadow={"md"}>
              <Table variant="simple" bg={"white"}>
                <Thead bg="cyan.400">
                  <Tr>
                    <Th>Cirugía</Th>
                    <Th>Doctor tratante</Th>
                    <Th>Fecha</Th>
                    <Th>Acciones</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {surgeries.map((elem) => (
                    <Tr key={elem.id}>
                      <Td>{elem.tipoCirugium.descripcion}</Td>
                      <Td>{elem.doctor_tratante}</Td>
                      <Td>{formatDate(elem.createdAt)}</Td>
                      <Td>
                        <Tooltip hasArrow label="Ver cirugía">
                          <IconButton
                            colorScheme="cyan"
                            fontSize=".75rem"
                            aria-label="Ver cirugia"
                            icon={<FaSearch />}
                            onClick={() => navigate(`/cirugia/${elem.id}`)}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Container>
        </ScaleFade>
      )}
    </>
  );
};
