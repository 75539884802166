import React, { useState } from "react";
import {
  Box,
  Center,
  FormControl,
  FormLabel,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { FaSave } from "react-icons/fa";
import { Form, Formik } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ButtonWithIcon } from "../CustomButtons";
import {es} from "date-fns/locale";
registerLocale("es", es)

const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export const SurgeryForm = ({
  initialValues,
  isLoading,
  onSubmit,
  professionals,
  catheters,
  typeSurgeries,
  setRangeDateFormatted,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    const formattedStartDate = start && formatDate(start);
    const formattedEndDate = end && formatDate(end);
    const formattedRange = `${formattedStartDate} / ${formattedEndDate}`;

    setRangeDateFormatted(formattedRange);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values = initialValues, handleBlur, handleChange, handleSubmit }) => (
        <Form>
          <FormControl name="doctor_tratante" onChange={handleChange} mt={2}>
            <FormLabel>Médico</FormLabel>
            <Select
              name="doctor_tratante"
              defaultValue={values.nombre}
              placeholder="Seleccionar"
            >
              {professionals.map(({ id, nombre, apellidos }) => (
                <option key={id} value={`${nombre} ${apellidos}`}>
                  {`${nombre} ${apellidos}`}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl name="id_tipoCirugia" onChange={handleChange} mt={2}>
            <FormLabel>Tipo cirugía</FormLabel>
            <Select
              name="id_tipoCirugia"
              defaultValue={values.id_tipoCirugia}
              placeholder="Seleccionar"
            >
              {typeSurgeries.map(({ id, descripcion }) => (
                <option key={id} value={id}>
                  {descripcion}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl name="id_cateter" onChange={handleChange} mt={2}>
            <FormLabel>Catéter</FormLabel>
            <Select
              name="id_cateter"
              defaultValue={values.id_cateter}
              placeholder="Seleccionar"
            >
              {catheters.map(
                ({ id, marca, modelo, descripcion, longitud, grosor }) => (
                  <option key={id} value={id}>
                    {`${marca} | ${modelo} ${descripcion} / ${longitud} ${grosor}`}
                  </option>
                )
              )}
            </Select>

            <FormControl name="fecha_retiro" mt={2}>
              <FormLabel>Fecha de retiro catéter</FormLabel>
              <DatePicker
                calendarClassName="date-range-picker"
                dateFormat="dd/MM/yyyy MMMM yyyy"
                endDate={endDate}
                inline
                isClearable={(endDate || startDate) && true}
                locale={"es"}
                onChange={handleDateChange}
                placeholderText="Seleccionar un rango"
                selectsRange
                showIcon
                startDate={startDate}
              />
            </FormControl>

            <Box mt={2}>
              <FormLabel>Procedimiento realizado</FormLabel>
              <Textarea
                name="procedimiento_realizado"
                value={values.procedimiento_realizado}
                onChange={handleChange}
                placeholder="Escribe el procedimiento realizado aquí..."
                size="md"
              />
            </Box>
          </FormControl>

          <Center mt={5}>
            <ButtonWithIcon
              fn={handleSubmit}
              titleButton="Finalizar"
              titleIcon={FaSave}
              isLoading={isLoading}
            />
          </Center>
        </Form>
      )}
    </Formik>
  );
};
