/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ExportToExcel } from "../components/ExportToExcel";
import { Box, Container, Flex, ScaleFade, Text } from "@chakra-ui/react";
import { RenderLoading } from "../components/RenderAlert";
import { SurgeryContext } from "../context/surgery/SurgeryContext";

export const ResearchDataScreen = () => {
  const { getsAllReports, reports } = useContext(SurgeryContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const all_quizzes = async () => {
      try {
        setIsLoading(true);
        await getsAllReports();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    all_quizzes();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            shadow={"md"}
            rounded={"md"}
            maxW={"full"}
            py={8}
          >
            <Box>
              <Flex direction="column" align="flex-start">
                <Text fontSize="xl" fontWeight="semibold" mb={2}>
                  Descarga de todas las encuestas realizadas por cirugía por
                  paciente.
                </Text>
                <ExportToExcel data={reports} />
              </Flex>
            </Box>
          </Container>
        </ScaleFade>
      )}
    </>
  );
};
