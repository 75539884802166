import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { FaFileExcel } from "react-icons/fa";
import { ButtonWithIcon } from "./CustomButtons";

export const ExportToExcel = ({ data }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const generateExcelData = () => {
    if (!data) {
      return [];
    }

    const excludedKeys = [
      "id",
      "id_paciente",
      "id_cirugia",
      "createdAt",
      "updatedAt",
      "is_habilitado",
      "id_profesional",
      "id_paciente",
      "id_cateter",
      "id_tipoCirugia",
      "fecha_creacion",
    ];

    return data.map((item) => {
      const data = {};
      for (const key in item) {
        if (!excludedKeys.includes(key)) {
          data[key] = item[key];
          if (item?.cirugium !== undefined) {
            for (const key2 in item.cirugium) {
              if (!excludedKeys.includes(key2)) {
                data[key2] = item.cirugium[key2];
                if (item.cirugium?.paciente !== undefined) {
                  for (const key3 in item.cirugium.paciente) {
                    if (!excludedKeys.includes(key3)) {
                      data[key3] = item.cirugium.paciente[key3];
                    }
                  }
                }
              }
            }
          }
        }
      }
      return data;
    });
  };

  const exportToExcel = () => {
    if (data) {
      const fileName = "Resultados reportes";
      const excelData = generateExcelData();
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  return (
    <ButtonWithIcon
      fn={exportToExcel}
      titleIcon={FaFileExcel}
      titleButton="Exportar Excel"
    >
      Excel
    </ButtonWithIcon>
  );
};
