/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FaFileMedical } from "react-icons/fa";
import { ButtonWithIcon } from "../CustomButtons";
import { SurgeryForm } from "../Forms/SurgeryForm";
import { PatientContext } from "../../context/patient/PatientContext";
import { SurgeryContext } from "../../context/surgery/SurgeryContext";
import { ProfessionalContext } from "../../context/professional/ProfessionalContext";

const initialValues = {
  doctor_tratante: "",
  id_tipoCirugia: "",
  id_cateter: "",
  procedimiento_realizado: "",
  fecha_retiro: "",
  descripcion: "",
};

export const CrearCirugiaUI = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { patient } = useContext(PatientContext);
  const { getsProfessionals, professionals } = useContext(ProfessionalContext);
  const {
    getsCatheters,
    catheters,
    createSurgery,
    getsTypeSurgeries,
    type_surgeries,
  } = useContext(SurgeryContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [rangeDateFormatted, setRangeDateFormatted] = useState(null);

  const handleCreate = async (values, { setSubmitting }) => {
    setIsLoading(true);
    await createSurgery({ ...values, fecha_retiro: rangeDateFormatted,  id_paciente: patient.id });
    setSubmitting(false);
    setIsLoading(false);
    setFormValues(initialValues);
    onClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getsProfessionals();
      await getsCatheters();
      await getsTypeSurgeries();
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <ButtonWithIcon
        fn={onOpen}
        titleIcon={FaFileMedical}
        titleButton={"Nueva cirugía"}
      />

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nueva Cirugía</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SurgeryForm
              initialValues={formValues}
              isLoading={isLoading}
              onSubmit={handleCreate}
              professionals={professionals}
              catheters={catheters}
              typeSurgeries={type_surgeries}
              setRangeDateFormatted={setRangeDateFormatted}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
