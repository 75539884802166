/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";

import AuthContext from "../../context/auth/AuthContext";
import { PatientContext } from "../../context/patient/PatientContext";
import { ProfessionalContext } from "../../context/professional/ProfessionalContext";
import { UsuarioForm } from "../Forms/UsuarioForm";
import { formatearRut } from "../../utils/formatearRut";
import { PatientForm } from "../Forms/PatientForm";

export const UserInfoModal = ({ initialValues }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateProfessional } = useContext(ProfessionalContext);
  const { updatePatient } = useContext(PatientContext);
  const { rol } = useContext(AuthContext);

  const [rut, setRut] = useState(initialValues.rut);
  const [isLoading, setIsLoading] = useState(false);

  const handleRutChange = (event) => {
    const formattedRut = formatearRut(event.target.value);
    setRut(formattedRut);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = { ...values, rut };
    setIsLoading(true);
    rol.nombre === "Profesional"
      ? await updateProfessional(data)
      : await updatePatient(data);

    setSubmitting(false);
    setIsLoading(false);
    onClose();
  };

  return (
    <>
      <IconButton
        variant="ghost"
        colorScheme="blue"
        fontSize="20px"
        size={"sm"}
        aria-label="Edit profile"
        onClick={onOpen}
        icon={<FaEdit />}
      />

      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={"xl"}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>Editar información</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {rol.nombre === "Profesional" ? (
              <UsuarioForm
                handleRutChange={handleRutChange}
                initialValues={initialValues}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                rut={rut}
              />
            ) : (
              <PatientForm
                handleRutChange={handleRutChange}
                initialValues={initialValues}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                rut={rut}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
