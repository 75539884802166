import React from "react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaIdCard } from "react-icons/fa";
import { ButtonWithIcon } from "../CustomButtons";

export const TablaPaciente = ({ patients }) => {
  const navigate = useNavigate();

  const handleShowPatient = (id) => {
    navigate(`/paciente/${id}`);
  };

  return (
    <>
      {patients.length > 0 ? (
        <TableContainer borderRadius={10} shadow={"md"}>
          <Table variant="simple" bg={"white"}>
            <Thead bg="cyan.400">
              <Tr>
                <Th>Paciente</Th>
                <Th>RUT</Th>
                <Th>Ficha</Th>
              </Tr>
            </Thead>
            <Tbody>
              {patients.map(({ id, nombre, apellidos, rut }) => (
                <Tr key={id}>
                  <Td>{`${nombre} ${apellidos}`}</Td>
                  <Td>{rut}</Td>
                  <Td>
                    <ButtonWithIcon
                      key={id}
                      fn={() => handleShowPatient(id)}
                      titleIcon={FaIdCard}
                      titleButton={"Ficha"}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <h2>Sin resultados, intente nuevamente.</h2>
      )}
    </>
  );
};
