export const formatDate = (inputDate) => {
  const dateObj = new Date(inputDate);

  // Obtiene los componentes de día, mes y año
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1; // Los meses en Date empiezan desde 0
  const year = dateObj.getFullYear();

  // Formatea los componentes con ceros a la izquierda si es necesario (para tener dos dígitos)
  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");

  // Concatena los componentes formateados en el formato deseado "dd-mm-yyyy"
  const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

  return formattedDate;
};

export const formatDateWithoutTimer = (inputDate) => {
  const parts = inputDate.split("-"); // Divide la cadena en partes: año, mes, día
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  // Concatena los componentes formateados en el formato deseado "dd-mm-yyyy"
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
};
