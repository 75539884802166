/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Box,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { formatDate } from "../../utils/formatDate";

export const TablaReportesCirugia = ({ reports }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    field: "createdAt",
    direction: "desc",
  });

  const reportsPerPage = 5;

  const handleSort = (field) => {
    if (sortConfig.field === field) {
      setSortConfig((prevConfig) => ({
        field,
        direction: prevConfig.direction === "asc" ? "desc" : "asc",
      }));
    } else {
      setSortConfig({ field, direction: "asc" });
    }
  };

  const sortedReports = [...reports].sort((a, b) => {
    const { field, direction } = sortConfig;
    if (a[field] < b[field]) return direction === "asc" ? -1 : 1;
    if (a[field] > b[field]) return direction === "asc" ? 1 : -1;
    return 0;
  });

  const totalReports = sortedReports.length;
  const totalPages = Math.ceil(totalReports / reportsPerPage);

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = sortedReports.slice(
    indexOfFirstReport,
    indexOfLastReport
  );

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <TableContainer mt={2} borderRadius={10} shadow={"md"}>
        <Table variant="simple" bg={"white"} borderColor={"black"}>
          <Thead bg="cyan.400">
            <Tr>
              <Th>#</Th>
              <Th>Tipo reporte</Th>
              <Th onClick={() => handleSort("createdAt")}>Fecha Reporte</Th>
              <Th>Resumen</Th>
            </Tr>
          </Thead>

          <Tbody>
            {currentReports.length > 0 ? (
              currentReports.map((report, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>
                    <>
                      {report.tipo_cuestionario === "eva"
                        ? "Encuesta EVA"
                        : "Cuestionario USSQ"}
                    </>
                  </Td>
                  <Td>{formatDate(report.createdAt)}</Td>
                  <Td>Resumen encuesta...pronto</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={6}>
                  No hay reportes o encuestas respondidas aún.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        {currentPage !== 1 && (
          <Icon
            _hover={{ cursor: "pointer" }}
            as={FaChevronLeft}
            bg={"gray.400"}
            boxSize={7}
            mr={2}
            onClick={goToPreviousPage}
            p={2}
            rounded={"full"}
          />
        )}

        {Array.from({ length: totalPages }).map((_, index) => (
          <Text
            key={index}
            as={"b"}
            fontSize={currentPage === index + 1 ? "2xl" : "sm"}
            color={currentPage === index + 1 ? "gray.600" : "gray.400"}
            mx={2}
          >
            {index + 1}
          </Text>
        ))}

        {currentReports.length === reportsPerPage && (
          <Icon
            _hover={{ cursor: "pointer" }}
            as={FaChevronRight}
            bg={"gray.400"}
            boxSize={7}
            ml={2}
            onClick={goToNextPage}
            p={2}
            rounded={"full"}
          />
        )}
      </Box>
    </>
  );
};
