import React, { useContext, useState } from "react";
import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";

import { PatientContext } from "../../context/patient/PatientContext";
import { PatientForm } from "../Forms/PatientForm";
import { formatearRut } from "../../utils/formatearRut";

export const EditarPacienteUI = ({ patient }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updatePatient } = useContext(PatientContext);

  const [isLoading, setIsLoading] = useState(false);
  const [rut, setRut] = useState(patient.rut);

  const handleRutChange = (event) => {
    const formattedRut = formatearRut(event.target.value);
    setRut(formattedRut);
  };

  const handleUpdate = async (values, { setSubmitting }) => {
    const data = { ...values, rut };

    setIsLoading(true);
    await updatePatient(data);
    setSubmitting(false);
    setIsLoading(false);
    onClose();
  };

  return (
    <>
      <Tooltip hasArrow label="Editar paciente">
        <IconButton
          aria-label="editar paciente"
          color={"white"}
          colorScheme="cyan"
          icon={<FaEdit />}
          ml={2}
          onClick={onOpen}
          shadow={"md"}
          size="md"
        />
      </Tooltip>

      <Modal
        blockScrollOnMount={false}
        isCentered
        isOpen={isOpen}
        motionPreset="slideInBottom"
        onClose={onClose}
        scrollBehavior="inside"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editando paciente</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PatientForm
              handleRutChange={handleRutChange}
              initialValues={patient}
              isLoading={isLoading}
              onSubmit={handleUpdate}
              rut={rut}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
