import React from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import HomeScreen from "../screens/HomeScreen";
import { Navbar } from "../components/navbar/Navbar";
import { PatientScreen } from "../screens/PatientScreen";
import { PatientsScreen } from "../screens/PatientsScreen";
import { ProfileScreen } from "../screens/ProfileScreen";
import { UsersScreen } from "../screens/UsersScreen";
import { QuestionnaireScreen } from "../screens/QuestionnaireScreen";
import { CathetersScreen } from "../screens/CathetersScreen";
import { SurgeriesScreen } from "../screens/SurgeriesScreen";
import { SurgeryScreen } from "../screens/SurgeryScreen";
import { ReportsOptionsScreen } from "../screens/ReportsOptionsScreen";
import { QuestionnaireScreenEVA } from "../screens/QuestionnaireEVAScreen";
import { FeedbackScreen } from "../screens/FeedbackScreen";
import { ReportScreen } from "../screens/ReportScreen";
import { ResearchDataScreen } from "../screens/ResearchDataScreen";

/*
  Router para todas las vistas internas de la plataforma con su path correspondiente.
  Si se desea agregar una nueva vista a la aplicacion, se debe ingresar como una ruta aca primero
*/

const smMarginSize = "0";
const mdMarginSize = "15rem";

const HomeRouter = () => {
  const marginSize = useBreakpointValue({
    base: smMarginSize,
    md: mdMarginSize,
  });
  return (
    <Box>
      <Navbar>
        <Box marginLeft={marginSize}>
          <Routes>
            <Route path="/" element={<HomeScreen />} />

            <Route path="/pacientes" element={<PatientsScreen />} />
            <Route path="/paciente/:idpaciente" element={<PatientScreen />} />

            <Route path="/mis_cirugias" element={<SurgeriesScreen />} />
            <Route path="/reportar" element={<ReportScreen />} />
            <Route path="/cirugia/:idcirugia" element={<SurgeryScreen />} />
            <Route
              path="/cirugia/:idcirugia/reportes"
              element={<ReportsOptionsScreen />}
            />

            <Route path="/usuarios" element={<UsersScreen />} />
            <Route
              path="/cirugia/:idcirugia/reportes/cuestionario-ussq"
              element={<QuestionnaireScreen />}
            />
            <Route
              path="/cirugia/:idcirugia/reportes/cuestionario-eva"
              element={<QuestionnaireScreenEVA />}
            />
            <Route
              path="/datos-investigacion"
              element={<ResearchDataScreen />}
            />
            <Route path="/cateteres" element={<CathetersScreen />} />
            <Route path="/sugerencias" element={<FeedbackScreen />} />

            <Route path="/perfil" element={<ProfileScreen />} />
          </Routes>
        </Box>
      </Navbar>
    </Box>
  );
};

export default HomeRouter;
