import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import { API } from "../../api/api";

const RegistroScreen = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [formValues, handleInputChange] = useForm({
    nombre: "",
    apellidos: "",
    fecha_nacimiento: "",
    email: "",
    rut: "",
    dv: "",
    telefono: "",
    genero: "",
    prevision: "",
    contraseña: "",
  });

  const {
    nombre,
    apellidos,
    fecha_nacimiento,
    email,
    rut,
    dv,
    telefono,
    genero,
    prevision,
    contraseña,
  } = formValues;

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      API.post("/auth/registro/", {
        ...formValues,
      }).then((res) => {
        API.post("/ficha", {
          paciente: `${nombre} ${apellidos}`,
          id_persona: res.data.persona.id,
        });
        toast({
          title: "Cuenta creada.",
          description: "Te has registrado exitosamente.",
          status: "success",
          duration: 1500,
          isClosable: true,
        });
        navigate("/");
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "El rut se encuentra registrado.",
        status: "error",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  return (
    <div className="contenedor__form--register">
      <div className="bg__register--img" />

      <div className="contenedor__form--contenido-register">
        <form className="form__register--box" onSubmit={handleSubmit}>
          <div className="side-by-side">
            <FormControl>
              <FormLabel>Nombre</FormLabel>
              <Input
                autoComplete="off"
                borderColor="#a9a9a9"
                className="form__register--input"
                name="nombre"
                onChange={handleInputChange}
                value={nombre}
                _hover={{
                  borderColor: "#a9a9a9",
                }}
                _focus={{ borderColor: "#262626" }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Apellidos</FormLabel>
              <Input
                autoComplete="off"
                borderColor="#a9a9a9"
                className="form__register--input"
                name="apellidos"
                onChange={handleInputChange}
                value={apellidos}
                _hover={{
                  borderColor: "#a9a9a9",
                }}
                _focus={{ borderColor: "#262626" }}
              />
            </FormControl>
          </div>

          <div className="side-by-side">
            <FormControl>
              <FormLabel>Fecha de Nacimiento</FormLabel>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="date"
                autoComplete="off"
                borderColor="#a9a9a9"
                className="form__register--input"
                name="fecha_nacimiento"
                onChange={handleInputChange}
                value={fecha_nacimiento}
                _hover={{
                  borderColor: "#a9a9a9",
                }}
                _focus={{ borderColor: "#262626" }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Correo electronico</FormLabel>
              <Input
                autoComplete="off"
                borderColor="#a9a9a9"
                className="form__register--input"
                name="email"
                onChange={handleInputChange}
                type="email"
                value={email}
                _hover={{
                  borderColor: "#a9a9a9",
                }}
                _focus={{ borderColor: "#262626" }}
              />
            </FormControl>
          </div>

          <div className="side-by-side">
            <FormControl>
              <FormLabel>RUT</FormLabel>
              <Input
                autoComplete="off"
                borderColor="#a9a9a9"
                className="form__register--input"
                name="rut"
                onChange={handleInputChange}
                // type="email"
                value={rut}
                _hover={{
                  borderColor: "#a9a9a9",
                }}
                _focus={{ borderColor: "#262626" }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>DV</FormLabel>
              <Input
                autoComplete="off"
                borderColor="#a9a9a9"
                className="form__register--input"
                name="dv"
                onChange={handleInputChange}
                // type="email"
                value={dv}
                _hover={{
                  borderColor: "#a9a9a9",
                }}
                _focus={{ borderColor: "#262626" }}
              />
            </FormControl>
          </div>

          <div className="side-by-side">
            <FormControl>
              <FormLabel>Telefono</FormLabel>
              <Input
                autoComplete="off"
                borderColor="#a9a9a9"
                className="form__register--input"
                name="telefono"
                onChange={handleInputChange}
                value={telefono}
                _hover={{
                  borderColor: "#a9a9a9",
                }}
                _focus={{ borderColor: "#262626" }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Genero</FormLabel>
              <Input
                autoComplete="off"
                borderColor="#a9a9a9"
                className="form__register--input"
                name="genero"
                onChange={handleInputChange}
                value={genero}
                _hover={{
                  borderColor: "#a9a9a9",
                }}
                _focus={{ borderColor: "#262626" }}
              />
            </FormControl>
          </div>

          <div className="side-by-side">
            <FormControl>
              <FormLabel>Previsión</FormLabel>
              <Input
                autoComplete="off"
                borderColor="#a9a9a9"
                className="form__register--input"
                name="prevision"
                onChange={handleInputChange}
                value={prevision}
                _hover={{
                  borderColor: "#a9a9a9",
                }}
                _focus={{ borderColor: "#262626" }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Contraseña</FormLabel>
              <Input
                borderColor="#a9a9a9"
                className="form__register--input"
                name="contraseña"
                onChange={handleInputChange}
                type="password"
                value={contraseña}
                _hover={{
                  borderColor: "#a9a9a9",
                }}
                _focus={{ borderColor: "#262626" }}
              />
            </FormControl>
          </div>

          <Button
            _active={{ transform: "scale(0.98)" }}
            _hover={{ bg: "#262626" }}
            bg="#000"
            color="white"
            mt={3}
            type="submit"
            width="full"
          >
            REGISTRAR
          </Button>
        </form>
        <div className="logo__register--img" />
      </div>
    </div>
  );
};

export default RegistroScreen;
