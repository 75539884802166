/* 
    maneja todas las funciones del authcontext que engloba todas las acciones de logueo o registro
*/

import React, { useReducer } from "react";
import { useToast } from "@chakra-ui/react";
import Cookies from "js-cookie";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import { API } from "../../api/api";

const AuthState = ({ children }) => {
  const toast = useToast();

  const userDataCookie = Cookies.get("userData2");
  let initialUserData = {};

  if (userDataCookie) {
    initialUserData = JSON.parse(userDataCookie);
  }

  const initialState = {
    usuario: initialUserData.usuario || {},
    rol: initialUserData.rol || {},
    isAuthenticated: initialUserData.isAuthenticated || false,
    isLoginLoading: false,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const startLogin = async (email, password) => {
    try {
      const { data } = await API.post("/auth/login/", {
        email: email.trim(),
        password: password.trim(),
      });

      const { user } = data;

      const userData = {
        usuario: user,
        rol: user.rol,
        token: data.token,
        isAuthenticated: true,
      };
      Cookies.set("userData2", JSON.stringify(userData), {
        expires: 1 / 96,
        secure: true,
      });

      dispatch({
        type: "LOGIN_SUCCESS",
        payload: data,
      });
    } catch (error) {
      handleError(error, "Login");
    }
  };

  const logoutRequest = async () => {
    Cookies.remove("userData2");
    sessionStorage.clear();
    dispatch({
      type: "LOGOUT",
      payload: [],
    });
  };

  const startLoading = (status) => {
    dispatch({
      type: "LOGIN_LOADING",
      payload: status,
    });
  };

  const handleError = async (error, process) => {
    try {
      console.log(error);
      dispatch({
        type: "LOGIN_LOADING",
        payload: false,
      });
      const bodyLog = {
        codigo: error.response.status,
        proceso: process,
        mensaje: error.response.data.message,
        respuesta: error,
      };
      await API.post("/logs", bodyLog);

      toast({
        description: error.response?.data?.message || "Ha ocurrido un error",
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        startLogin,
        logoutRequest,
        startLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
