/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardBody,
  Container,
  Divider,
  Heading,
  ScaleFade,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import { SurgeryContext } from "../context/surgery/SurgeryContext";
import { RenderLoading } from "../components/RenderAlert";
import { formatDate, formatDateWithoutTimer } from "../utils/formatDate";
import { TablaReportesCirugia } from "../components/Tablas/TablaReportesCirugia";
import { ButtonWithIcon } from "../components/CustomButtons";
import { FaFileMedical } from "react-icons/fa";
import AuthContext from "../context/auth/AuthContext";

export const SurgeryScreen = () => {
  const navigate = useNavigate();
  const { idcirugia } = useParams();
  const { usuario } = useContext(AuthContext);
  const { getsReportsBySurgery, getSurgery, surgery, reports } =
    useContext(SurgeryContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSurgery = async (id) => {
      setIsLoading(true);
      await getSurgery(id);
      await getsReportsBySurgery(id);
      setIsLoading(false);
    };

    fetchSurgery(idcirugia);
  }, [idcirugia]);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            shadow={"md"}
            rounded={"md"}
            maxW={"full"}
            py={8}
          >
            <Card boxShadow="md">
              <CardBody>
                <Stack spacing="2">
                  <Box display="flex" justifyContent="space-between">
                    <Heading size="md" textTransform="uppercase" fontSize="xl">
                      {surgery?.tipoCirugium?.descripcion}
                    </Heading>
                  </Box>

                  <Divider opacity={1} />

                  <Text>
                    <strong>Doctor tratante: </strong>
                    {surgery?.doctor_tratante}
                  </Text>

                  <Text>
                    <strong>Fecha cirugía: </strong>
                    {formatDate(surgery?.createdAt)}
                  </Text>

                  <Text>
                    <strong>Catéter utilizado: </strong>
                    {surgery?.cateter?.marca} {surgery?.cateter?.modelo}
                    {" / "}
                    <span>
                      {surgery?.cateter?.descripcion}{" "}
                      {surgery?.cateter?.longitud} {surgery?.cateter?.grosor}
                    </span>
                  </Text>

                  <Text>
                    <strong>Fecha de retiro estimada: </strong>
                    {surgery?.fecha_retiro &&
                      formatDateWithoutTimer(surgery?.fecha_retiro)}
                  </Text>

                  <Text>
                    <strong>Institución (Clínica): </strong>
                    {usuario?.paciente.profesional.institucion}
                  </Text>
                </Stack>
              </CardBody>
            </Card>

            <Box mt={6}>
              <ButtonWithIcon
                fn={() => navigate(`/cirugia/${idcirugia}/reportes`)}
                titleIcon={FaFileMedical}
                titleButton="Nuevo reporte"
              />
              <TablaReportesCirugia reports={reports} />
            </Box>
          </Container>
        </ScaleFade>
      )}
    </>
  );
};
