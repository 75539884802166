/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { RenderLoading } from "./RenderAlert";
import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  ScaleFade,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ProfileInfo } from "./ProfileInfo";
import { ProfilePassword } from "./ProfilePassword";
import AuthContext from "../context/auth/AuthContext";
import { ProfessionalContext } from "../context/professional/ProfessionalContext";

export const ProfileProContent = () => {
  const { usuario } = useContext(AuthContext);
  const { getProfessional, professional } = useContext(ProfessionalContext);

  const [isLoading, setIsLoading] = useState(false);

  const fullname = professional?.apellidos
    ? `${professional?.nombre} ${professional?.apellidos}`
    : `${professional?.nombre}`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getProfessional(usuario.id_profesional);
      setIsLoading(false);
      return;
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in>
          <Card
            boxShadow={"md"}
            borderWidth="1px"
            borderColor="gray.200"
            bg={"white.300"}
            rounded={"lg"}
          >
            <CardBody>
              <Box>
                <Flex justify="center" align="center">
                  <Avatar boxSize={"5rem"} bg="teal.500" mr={4} />
                  <VStack align={"flex-start"}>
                    <Heading as="h2" size={"xl"} color={"gray.600"}>
                      {fullname}
                    </Heading>
                    <Text>{professional?.email}</Text>
                  </VStack>
                </Flex>

                <Flex direction={{ base: "column", md: "row" }}>
                  <ProfileInfo user={professional} />

                  <ProfilePassword user={usuario} />
                </Flex>
              </Box>
            </CardBody>
          </Card>
        </ScaleFade>
      )}
    </>
  );
};
