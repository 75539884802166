import React, { useContext, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { EditarCateterUI } from "../Ui/EditarCateterUI";
import { FaTrash } from "react-icons/fa";
import { SurgeryContext } from "../../context/surgery/SurgeryContext";

export const TablaCateteres = ({ catheters }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteCatheter } = useContext(SurgeryContext);
  const [id, setId] = useState("");
  const cancelRef = React.useRef();

  const handleDelete = async () => {
    await deleteCatheter(id);
    onClose();
  };
  return (
    <>
      {catheters.length > 0 ? (
        <TableContainer borderRadius={10} shadow={"md"} mt={2}>
          <Table variant="simple" bg={"white"}>
            <Thead bg="cyan.400">
              <Tr>
                <Th>Marca</Th>
                <Th>Modelo</Th>
                <Th>Descripción</Th>
                <Th>Longitud</Th>
                <Th>Grosor</Th>
                <Th>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {catheters.map((catheter) => (
                <Tr key={catheter.id}>
                  <Td>{catheter.marca}</Td>
                  <Td>{catheter.modelo}</Td>
                  <Td>{catheter.descripcion}</Td>
                  <Td>{catheter.longitud}</Td>
                  <Td>{catheter.grosor}</Td>
                  <Td>
                    <>
                      <EditarCateterUI catheter={catheter} />

                      <Tooltip label="Eliminar" aria-label="eliminar cateter">
                        <IconButton
                          aria-label="eliminar cateter"
                          icon={<FaTrash />}
                          onClick={() => {
                            setId(catheter.id);
                            onOpen();
                          }}
                        />
                      </Tooltip>
                    </>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <h2>Sin resultados, intente nuevamente.</h2>
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Eliminar Catéter
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estás seguro? No puedes deshacer esta acción después.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
