import React from "react";
import { Center } from "@chakra-ui/react";
import { FaSave } from "react-icons/fa";
import { Form, Formik } from "formik";

import { InputNormal } from "./InputForm";
import { ButtonWithIcon } from "../CustomButtons";

export const CatheterForm = ({ initialValues, isLoading, onSubmit }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values = initialValues, handleBlur, handleChange, handleSubmit }) => (
        <Form>
          <InputNormal
            label="Marca"
            name="marca"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.marca}
          />

          <InputNormal
            label="Modelo"
            name="modelo"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.modelo}
          />

          <InputNormal
            label="Descripción"
            name="descripcion"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.descripcion}
          />

          <InputNormal
            label="Longitud"
            name="longitud"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.longitud}
          />
          <InputNormal
            label="Grosor"
            name="grosor"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.grosor}
          />

          <Center mt={5}>
            <ButtonWithIcon
              fn={handleSubmit}
              titleButton="Finalizar"
              titleIcon={FaSave}
              isLoading={isLoading}
            />
          </Center>
        </Form>
      )}
    </Formik>
  );
};
