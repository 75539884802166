import React from "react";
import { Center, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { FaSave } from "react-icons/fa";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { InputNormal, InputRut } from "./InputForm";
import { ButtonWithIcon } from "../CustomButtons";

const validationSchema = Yup.object({
  rut: Yup.string().matches(
    /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/,
    "RUT inválido"
  ),
  nombre: Yup.string().required("Obligatorio"),
  apellidos: Yup.string().required("Obligatorio"),
  email: Yup.string().email("Correo invalido").required("Obligatorio"),
});

export const PatientForm = ({
  handleRutChange,
  initialValues,
  isLoading,
  onSubmit,
  rut,
}) => {
  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Form>
          <InputRut
            label="RUT"
            name="rut"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleRutChange}
            value={rut}
          />
          {touched.rut && errors.rut ? <span>{errors.rut}</span> : null}

          <InputNormal
            label="Nombre"
            name="nombre"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.nombre}
          />
          {touched.nombre && errors.nombre ? (
            <span>{errors.nombre}</span>
          ) : null}

          <InputNormal
            label="Apellidos"
            name="apellidos"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.apellidos}
          />
          {touched.apellidos && errors.apellidos ? (
            <span>{errors.apellidos}</span>
          ) : null}

          <InputNormal
            label="Correo electrónico"
            name="email"
            type="email"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.email}
          />
          {touched.email && errors.email ? <span>{errors.email}</span> : null}

          <InputNormal
            label="Telefono"
            name="telefono"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.telefono}
          />

          <InputNormal
            label="Dirección"
            name="direccion"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.direccion}
          />

          <InputNormal
            label="Fecha de Nacimiento"
            name="fecha_nacimiento"
            type="date"
            max={currentDate}
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.fecha_nacimiento}
          />

          <InputNormal
            label="Previsión"
            name="prevision"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.prevision}
          />

          <FormControl name="sexo" onChange={handleChange}>
            <FormLabel>Sexo</FormLabel>
            <Select
              name="sexo"
              defaultValue={values.sexo}
              placeholder="Seleccionar"
            >
              <option value="Masculino">Masculino</option>
              <option value="Femenino">Femenino</option>
              <option value="Otro">Otro</option>
            </Select>
          </FormControl>

          <FormControl name="estado_civil" onChange={handleChange}>
            <FormLabel>Estado civil</FormLabel>
            <Select
              name="estado_civil"
              defaultValue={values.estado_civil}
              placeholder="Seleccionar"
            >
              <option value="Soltero">Soltero</option>
              <option value="Casado">Casado</option>
              <option value="Otro">Otro</option>
            </Select>
          </FormControl>

          <Center mt={5}>
            <ButtonWithIcon
              fn={handleSubmit}
              titleButton="Guardar"
              titleIcon={FaSave}
              isLoading={isLoading}
            />
          </Center>
        </Form>
      )}
    </Formik>
  );
};
