import {
  GETS_CATHETERS,
  GETS_SURGERIES,
  GET_SURGERY,
  GETS_TYPE_SURGERIES,
  GETS_REPORTS,
} from "./actions";

export const SurgeryReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case GETS_CATHETERS:
      return {
        ...state,
        catheters: payload,
      };

    case GETS_SURGERIES:
      return {
        ...state,
        surgeries: payload,
      };

    case GET_SURGERY:
      return {
        ...state,
        surgery: payload,
      };

    case GETS_TYPE_SURGERIES:
      return {
        ...state,
        type_surgeries: payload,
      };

    case GETS_REPORTS:
      return {
        ...state,
        reports: payload,
      };

    default:
      return state;
  }
};
