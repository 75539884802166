import React, { useContext } from "react";
import { Box, Container, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { FaInfo } from "react-icons/fa";
import { UserInfoModal } from "./Modals/UserInfoModal";
import AuthContext from "../context/auth/AuthContext";

export const ProfileInfo = ({ user }) => {
  const { rol } = useContext(AuthContext);
  return (
    <>
      {user && (
        <Container
          mt={12}
          fontSize={"lg"}
          color={"gray.600"}
          borderRightWidth={{ base: 0, md: 2 }}
          borderBottomWidth={{ base: 2, md: 0 }}
          pb={{ base: 4, md: 0 }}
        >
          <HStack mb={4} justify={"space-between"}>
            <Box>
              <Icon as={FaInfo} />
              <Text as={"b"} fontSize={"xl"}>
                Información básica
              </Text>
            </Box>

            <UserInfoModal initialValues={user} />
          </HStack>
          <Stack>
            <HStack>
              <Text as={"b"}>Nombre:</Text>
              <Text>{user.nombre}</Text>
            </HStack>

            <HStack>
              <Text as={"b"}>Apellidos:</Text>
              <Text>{user.apellidos}</Text>
            </HStack>

            <HStack>
              <Text as={"b"}>RUT:</Text>
              <Text>{user.rut}</Text>
            </HStack>

            <HStack>
              <Text as={"b"}>Correo electrónico:</Text>
              <Text>{user.email}</Text>
            </HStack>

            {rol.nombre === "Profesional" && (
              <HStack>
                <Text as={"b"}>Institución:</Text>
                <Text>{user.institucion}</Text>
              </HStack>
            )}

            <HStack>
              <Text as={"b"}>Teléfono:</Text>
              <Text>{user.telefono}</Text>
            </HStack>

            {rol.nombre === "Paciente" && (
              <>
                <HStack>
                  <Text as={"b"}>Dirección:</Text>
                  <Text>{user.direccion}</Text>
                </HStack>
                <HStack>
                  <Text as={"b"}>Fecha de nacimiento:</Text>
                  <Text>{user.fecha_nacimiento}</Text>
                </HStack>
                <HStack>
                  <Text as={"b"}>Previsión:</Text>
                  <Text>{user.prevision}</Text>
                </HStack>
                <HStack>
                  <Text as={"b"}>Sexo:</Text>
                  <Text>{user.sexo}</Text>
                </HStack>
                <HStack>
                  <Text as={"b"}>Estado civil:</Text>
                  <Text>{user.estado_civil}</Text>
                </HStack>
              </>
            )}
          </Stack>
        </Container>
      )}
    </>
  );
};
