import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { formatDate } from "../utils/formatDate";
import { FaFileMedicalAlt } from "react-icons/fa";

export const ReportCard = ({ report }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const keys = Object.keys(report).filter(
    (key) =>
      ![
        "id",
        "tipo_cuestionario",
        "id_cirugia",
        "createdAt",
        "updatedAt",
        "id_paciente",
      ].includes(key)
  );

  return (
    <>
      <Box
        maxW={{ base: "full" }}
        shadow={"md"}
        bg={"white"}
        rounded={"lg"}
        overflow="hidden"
        p={5}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Stack align={"start"} spacing={2} h={"100%"}>
          <Flex
            w={8}
            h={8}
            align={"center"}
            justify={"center"}
            color={"white"}
            rounded={"full"}
            bg={"gray.500"}
          >
            {<FaFileMedicalAlt />}
          </Flex>
          <Box mt={2}>
            <Heading size="sm">
              {report.tipo_cuestionario === "eva"
                ? "Reporte EVA"
                : "Reporte USSQ"}
            </Heading>
            <Text>
              <strong>Fecha reporte: </strong>
              {formatDate(report.createdAt)}
            </Text>
          </Box>
        </Stack>

        <Box mt={2} alignSelf={"flex-end"}>
          <Button
            colorScheme={"blue"}
            onClick={onOpen}
            size={"sm"}
            variant={"link"}
          >
            Ver reporte
          </Button>
        </Box>
      </Box>

      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size={report.tipo_cuestionario === "eva" ? "md" : "4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{report.tipo_cuestionario.toUpperCase()}</ModalHeader>
          <ModalBody>
            {report.tipo_cuestionario === "eva" ? (
              <Text>
                <strong>Escala de dolor: </strong>
                {report.escala_dolor}
              </Text>
            ) : (
              <Table variant={"unstyled"} size={"sm"}>
                <Thead>
                  <Tr>
                    {keys.map((key) => (
                      <Th
                        key={key}
                        borderColor={"gray.400"}
                        borderWidth={1}
                        textAlign={"center"}
                      >
                        {key}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    {keys.map((key) => (
                      <Td
                        key={key}
                        borderColor={"gray.400"}
                        borderWidth={1}
                        whiteSpace={"nowrap"}
                      >
                        {report[key]}
                      </Td>
                    ))}
                  </Tr>
                </Tbody>
              </Table>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="cyan" color="white" mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
