import React from "react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { EditarUsuarioUI } from "../Ui/EditarUsuarioUI";

export const TablaUsuarios = ({ users }) => {
  return (
    <>
      {users.length > 0 ? (
        <TableContainer borderRadius={10} shadow={"md"}>
          <Table variant="simple">
            <Thead bg="cyan.400">
              <Tr>
                <Th>Usuario</Th>
                <Th>RUT</Th>
                <Th>Rol</Th>
                <Th>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.id}>
                  <Td>
                    {user.apellidos
                      ? `${user.nombre} ${user.apellidos}`
                      : `${user.nombre}`}
                  </Td>
                  <Td>{user.rut}</Td>
                  <Td>{user.permiso.rol.nombre}</Td>
                  <Td>
                    <EditarUsuarioUI usuario={user} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <h2>Sin resultados, intente nuevamente.</h2>
      )}
    </>
  );
};
