import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";

export const ViewSurgeryModal = ({ surgery }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataSurgery, setDataSurgery] = useState({});

  const handleView = (surgery) => {
    setDataSurgery(surgery);
    onOpen();
  };

  return (
    <>
      <Tooltip hasArrow label="Ver datos cirugía">
        <IconButton
          ml={2}
          fontSize=".75rem"
          variant={"ghost"}
          aria-label="Ver datos cirugía"
          icon={<FaSearch />}
          onClick={() => handleView(surgery)}
        />
      </Tooltip>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cirugía</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              <strong>Tipo de cirugía: </strong>
              {dataSurgery?.tipoCirugium?.descripcion}
            </Text>
            <Text>
              <strong>Médico tratante: </strong>
              {dataSurgery.doctor_tratante}
            </Text>

            <Text>
              <strong>Procedimiento realizado: </strong>
              {dataSurgery.procedimiento_realizado}
            </Text>

            <Card>
              <CardBody>
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Catéter
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {`${dataSurgery.cateter?.marca} - ${dataSurgery.cateter?.modelo}`}
                  </Text>
                  <Text pt="2" fontSize="sm">
                    {`${dataSurgery.cateter?.descripcion} / ${dataSurgery.cateter?.longitud} ${dataSurgery.cateter?.grosor}`}
                  </Text>
                </Box>
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
