/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  //Button,
  Icon,
  //useDisclosure,
  /* AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter, */
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

/* import { useNavigate, useParams } from "react-router-dom";
import { lanekApi } from "../api/lanekApi"; */
// import { RenderLoading } from "../components/RenderAlert";
import { FaClipboardList } from "react-icons/fa";
import { ReportOptionCard } from "../components/ReportOptionCard";
import { useNavigate, useParams } from "react-router-dom";

const productos = [
  {
    id: "1",
    title: "Reporte de dolor (Escala EVA)",
    description: "Escala de intensidad del dolor.",
    icono: FaClipboardList,
    is_habilitado: true,
  },
  {
    id: "2",
    title: "Cuestionario USSQ",
    description: "Encuesta sobre los síntomas presentados.",
    icono: FaClipboardList,
    is_habilitado: true,
  },
];

export const ReportsOptionsScreen = ({ idSurgery }) => {
  const navigate = useNavigate();
  const { idcirugia } = useParams();
  //const { isOpen, onOpen, onClose } = useDisclosure();

  // const cancelRef = useRef();

  const handleSelect = (data) => {
    if (data === "1") {
      navigate(`/cirugia/${idcirugia || idSurgery}/reportes/cuestionario-eva`);
    }
    if (data === "2") {
      navigate(`/cirugia/${idcirugia || idSurgery}/reportes/cuestionario-ussq`);
    }
  };

  return (
    <>
      <Box maxW="7xl" mx={"auto"}>
        <SimpleGrid columns={{ base: 2, lg: 3 }} spacing={{ base: 5, lg: 8 }}>
          {productos.map(({ title, description, is_habilitado, id, icono }) => (
            <ReportOptionCard
              key={id}
              title={title}
              icon={<Icon as={icono} w={"2.5rem"} h={"2.5rem"} />}
              description={description}
              disable={!is_habilitado}
              value={title}
              handle={() => handleSelect(id)}
            />
          ))}
        </SimpleGrid>
      </Box>
    </>
  );
};
