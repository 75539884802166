import { GET_PATIENT, GET_PATIENTS } from "./actions";
export const PatientReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PATIENTS:
      return {
        ...state,
        patients: payload,
      };

    case GET_PATIENT:
      return {
        ...state,
        patient: payload,
      };

    default:
      return state;
  }
};
