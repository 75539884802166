/* 
  Router principal, se maneja un router privado y publico dependiendo si el usuario esta autenticado o no.
*/

import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AuthRouter from "./AuthRouter";
import HomeRouter from "./HomeRouter";
import PublicRouter from "./PublicRouter";
import PrivateRouter from "./PrivateRouter";

import { PatientState } from "../context/patient/PatientState";
import { ProfessionalState } from "../context/professional/ProfessionalState";
import { SurgeryState } from "../context/surgery/SurgeryState";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={
            <PrivateRouter>
              <ProfessionalState>
                <PatientState>
                  <SurgeryState>
                    <HomeRouter />
                  </SurgeryState>
                </PatientState>
              </ProfessionalState>
            </PrivateRouter>
          }
        />

        <Route
          path="/auth/*"
          element={
            <PublicRouter>
              <AuthRouter />
            </PublicRouter>
          }
        />

        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
