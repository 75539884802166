/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Input, ScaleFade } from "@chakra-ui/react";
import { TablaUsuarios } from "../components/Tablas/TablaUsuarios";
import { CrearUsuarioUI } from "../components/Ui/CrearUsuarioUI";
import { RenderLoading } from "../components/RenderAlert";
import { ProfessionalContext } from "../context/professional/ProfessionalContext";

export const UsersScreen = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { getsProfessionals, professionals } = useContext(ProfessionalContext);

  const usuariosFiltrados = () => {
    if (search.length === 0) return professionals;

    // Si hay algo en la barra de busqueda
    const filtrados = professionals.filter((professional) => {
      return (
        professional.nombre.toLowerCase().includes(search.toLowerCase()) ||
        professional.rut.toString().includes(search)
      );
    });
    return filtrados;
  };

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        await getsProfessionals();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            maxW={"full"}
            py={10}
            rounded={"md"}
            shadow={"md"}
          >
            <Box display="flex" justifyContent="center" mb={4}>
              <Input
                boxShadow="md"
                htmlSize={40}
                size="md"
                width="auto"
                variant="filled"
                value={search}
                onChange={onSearchChange}
                placeholder="Buscar usuario por nombre o rut"
              />

              <CrearUsuarioUI />
            </Box>

            <TablaUsuarios users={usuariosFiltrados()} />
          </Container>
        </ScaleFade>
      )}
    </>
  );
};
