import React, { useContext, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { FaUserEdit } from "react-icons/fa";

import { ProfessionalContext } from "../../context/professional/ProfessionalContext";
import { UsuarioForm } from "../Forms/UsuarioForm";
import { ButtonWithIcon } from "../CustomButtons";
import { formatearRut } from "../../utils/formatearRut";

export const EditarUsuarioUI = ({ usuario }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateProfessional } = useContext(ProfessionalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [rut, setRut] = useState(usuario.rut);

  const handleRutChange = (event) => {
    const formattedRut = formatearRut(event.target.value);
    setRut(formattedRut);
  };

  // const handleDelete = (usuario) => {
  //   lanekApi
  //     .delete(`/profesional/eliminar-profesional`, usuario)
  //     .then((msg) => console.log(msg))
  //     .catch((err) => console.log(err));
  // };

  const handleUpdate = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const dataCuenta = { ...values, rut: rut };
    await updateProfessional(dataCuenta);
    setSubmitting(false);
    setIsLoading(false);
    onClose();
  };

  return (
    <>
      <Stack direction={["column", "row"]}>
        <ButtonWithIcon
          fn={onOpen}
          titleIcon={FaUserEdit}
          titleButton="Editar"
        />

        {/* <ButtonWithIcon
          fn={() => handleDelete(usuario)}
          titleIcon={FaTrash}
          titleButton="Eliminar"
        /> */}
      </Stack>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editando organización</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UsuarioForm
              handleRutChange={handleRutChange}
              initialValues={usuario}
              isLoading={isLoading}
              onSubmit={handleUpdate}
              rut={rut}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
