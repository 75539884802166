import React, { useContext, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  NumberInput,
  Slider,
  Center,
  Image,
  Container,
  Spacer,
} from "@chakra-ui/react";
import { PatientContext } from "../context/patient/PatientContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import escala_dolor from "../assets/img/questionnaire/escala_dolor.png";
import { ButtonWithIcon } from "../components/CustomButtons";
import { FaReply } from "react-icons/fa";

export const QuestionnaireScreenEVA = () => {
  const { idcirugia } = useParams();
  const { pathname } = useLocation();
  const { createQuestionnaire } = useContext(PatientContext);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const [formularioValues, setFormularioValues] = useState({
    form: {
      escala_dolor: "",
    },
  });

  const handleChange = (value) => {
    setValue(value);
    setFormularioValues((prevValues) => ({
      ...prevValues,
      1: {
        ...prevValues[1],
        escala_dolor: value,
      },
    }));
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleFormSubmit = () => {
    // Puedes enviar los datos a la API REST de prueba aquí
    // console.log("Datos enviados desde el cuestionario eva:", formularioValues);
    createQuestionnaire({
      ...formularioValues,
      id_cirugia: idcirugia,
      tipo_cuestionario: pathname.includes("eva") ? "eva" : "ussq",
    });
    // Reiniciar el formulario después de enviar los datos
    setFormularioValues({
      form: {
        escala_dolor: "",
      },
    });
    navigate(`/cirugia/${idcirugia}`);
  };

  return (
    <>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        background="white"
        p={6}
        m="10px auto"
        as="form"
      >
        <Flex justify={"flex-start"} align={"flex-end"} mb={4}>
          <ButtonWithIcon
            fn={goBack}
            titleIcon={FaReply}
            titleButton={"Volver"}
            colorScheme="cyan"
            color={"white"}
          />
        </Flex>
        <Form
          formularioValues={formularioValues.form}
          handleChange={handleChange}
          value={value}
        />
        <Flex minWidth="max-content" alignItems="center" gap="2">
          <Spacer />
          <ButtonGroup gap="2">
            <Button
              w="7rem"
              colorScheme="red"
              variant="solid"
              onClick={handleFormSubmit}
            >
              Enviar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </>
  );
};

const Form = ({ handleChange, value }) => {
  return (
    <>
      <Heading
        w="100%"
        textAlign={"center"}
        fontWeight="bold"
        fontSize="lg"
        textDecorationLine="underline"
        mb="6%"
      >
        ESCALA VISUAL ANÁLOGA (EVA)
      </Heading>
      <Box mb="6%">
        <Center mt={5}>
          <Image
            height={"70%"}
            width={"70%"}
            src={escala_dolor}
            alt="Dan Abramov"
          />
        </Center>
      </Box>
      <FormControl name="escala_dolor" mb="2%">
        <Box
          borderWidth="1px"
          rounded="lg"
          shadow="1px 1px 3px rgba(0,0,0,0.3)"
          maxWidth={800}
          background="white"
          p={6}
          m="10px auto"
          as="form"
          mb="6%"
        >
          <Container>
            <Flex>
              <NumberInput
                maxW="100px"
                mr="2rem"
                name="escala_dolor"
                value={value}
                onChange={handleChange}
              ></NumberInput>
              <Slider
                flex="1"
                max={10}
                focusThumbOnChange={false}
                value={value}
                onChange={handleChange}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb fontSize="sm" boxSize="32px" children={value} />
              </Slider>
            </Flex>
          </Container>
        </Box>
      </FormControl>
    </>
  );
};
