import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { API } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { EmailIcon } from "@chakra-ui/icons";

export const CheckMail = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const [inputValue, setInputValue] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(e.target.value);
    handleInputChange(e);
    // Expresión regular para validar un correo electrónico simple
    const emailRegex = /^[^@\s]+@([^@\s])+$/;

    const isValid = emailRegex.test(inputValue);

    setIsValidEmail(isValid);
  };

  const handleInputChange = (e) => {
    if (isValidEmail) {
      setInputValue(e.target.value); // Actualiza el estado con el valor del campo
    } else {
      console.log("Correo electrónico no válido");
    }
  };

  const handleResetPassword = async () => {
    setIsLoading(true);
    // Envía la solicitud al servidor para recuperar la contraseña.
    try {
      const response = await API.post(`/auth/check-email?email=${email}`);
      if (response.data) {
        navigate("/auth/mail-send", { replace: true });        
      }
    } catch (error) {
      console.log(error);
      toast({
        description: "Su correo no se encuentra registrado.",
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack mx={"auto"} w={"md"} maxW={"lg"} py={12} px={6}>
            <Box rounded={"lg"} boxShadow={"lg"} p={8}>
              <Stack spacing={4}>
                <FormLabel mb="2%">Recuperación de contraseña</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<EmailIcon color="gray.300" />} // Icono de candado
                  />
                  <Input
                    type="email"
                    placeholder="Correo electrónico"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </InputGroup>
                <Button
                  onClick={handleResetPassword}
                  isDisabled={!inputValue.trim()}
                  isLoading={isLoading}
                >
                  Recuperar contraseña
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};
