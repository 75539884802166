import {
  GETS_FEEDBACKS,
  GET_FEEDBACK,
  GET_PROFESSIONAL,
  GET_PROFESSIONALS,
} from "./actions";

export const ProfessionalReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFESSIONALS:
      return {
        ...state,
        professionals: payload,
      };

    case GET_PROFESSIONAL:
      return {
        ...state,
        professional: payload,
      };

    case GETS_FEEDBACKS:
      return {
        ...state,
        feedbacks: payload,
      };

    case GET_FEEDBACK:
      return {
        ...state,
        feedback: payload,
      };

    default:
      return state;
  }
};
