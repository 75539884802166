import React, { useContext, useState } from "react";
import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { FaUserPlus } from "react-icons/fa";

import { PatientContext } from "../../context/patient/PatientContext";
import { PatientForm } from "../Forms/PatientForm";
import { formatearRut } from "../../utils/formatearRut";

const initialValues = {
  rut: "",
  nombre: "",
  apellidos: "",
  email: "",
  telefono: "",
  fecha_nacimiento: "",
  sexo: "",
  prevision: "",
  direccion: "",
  estado_civil: "",
};

export const CrearPacienteUI = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createPatient } = useContext(PatientContext);

  const [rut, setRut] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCreate = async (values, { setSubmitting }) => {
    setIsLoading(true);

    const dataFormulario = {
      ...values,
      rut: rut,
      password:
        rut.split("-")[0].replace(/\./g, "") || values.nombre.toLowerCase(),
      passwordForEmail:
        rut.split("-")[0].replace(/\./g, "") || values.nombre.toLowerCase(),
    };
    await createPatient(dataFormulario);
    setSubmitting(false);
    setIsLoading(false);
    setRut("");
    onClose();
  };

  const handleRutChange = (event) => {
    const formattedRut = formatearRut(event.target.value);
    setRut(formattedRut);
  };

  return (
    <>
      <Tooltip hasArrow label="Crear paciente">
        <IconButton
          ml={2}
          shadow={"md"}
          onClick={onOpen}
          size="md"
          colorScheme="cyan"
          color={"white"}
          aria-label="crear paciente"
          icon={<FaUserPlus />}
        />
      </Tooltip>

      <Modal
        blockScrollOnMount={false}
        isCentered
        isOpen={isOpen}
        motionPreset="slideInBottom"
        onClose={onClose}
        scrollBehavior="inside"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nuevo Paciente</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PatientForm
              handleRutChange={handleRutChange}
              initialValues={initialValues}
              isLoading={isLoading}
              onSubmit={handleCreate}
              rut={rut}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
