/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardBody,
  Container,
  Divider,
  Heading,
  IconButton,
  ScaleFade,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { PatientContext } from "../context/patient/PatientContext";
import { EditarPacienteUI } from "../components/Ui/EditarPacienteUI";
import { RenderLoading } from "../components/RenderAlert";
import { TablaCirugia } from "../components/Tablas/TablaCirugia";
import { CrearCirugiaUI } from "../components/Ui/CrearCirugiaUI";
import { SurgeryContext } from "../context/surgery/SurgeryContext";
import { ReportsScreen } from "./ReportsScreen";
import { FaTrash } from "react-icons/fa";

export const PatientScreen = () => {
  const navigate = useNavigate();
  const { idpaciente } = useParams();
  const { getPatient, deletePatient, patient } = useContext(PatientContext);
  const { getsSurgeriesByPatient, surgeries } = useContext(SurgeryContext);
  const [idCirugia, setIdCirugia] = useState("");
  const [viewReports, setViewReports] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async (id) => {
    setIsLoading(true);
    await deletePatient(id);
    navigate("/pacientes", { replace: true });
    setIsLoading(false);
  };

  const handleView = (id) => {
    setViewReports(!viewReports);
    setIdCirugia(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await getPatient(idpaciente);
        await getsSurgeriesByPatient(idpaciente);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [idpaciente]);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            shadow={"md"}
            rounded={"md"}
            maxW={"full"}
            py={8}
          >
            <Card boxShadow="md" className="animate__animated animate__fadeIn">
              <CardBody>
                <Stack spacing="2">
                  <Box display="flex" justifyContent="space-between">
                    <Heading
                      size="md"
                      textTransform="capitalize"
                      fontSize="2xl"
                    >
                      {`${patient.nombre} ${patient.apellidos}`}
                    </Heading>

                    <Box display="flex">
                      <EditarPacienteUI patient={patient} />

                      <Tooltip hasArrow label="Eliminar paciente">
                        <IconButton
                          aria-label="eliminar paciente"
                          color={"white"}
                          colorScheme="cyan"
                          icon={<FaTrash />}
                          ml={2}
                          onClick={() => handleDelete(patient.id)}
                          shadow={"md"}
                          size="md"
                        />
                      </Tooltip>
                    </Box>
                  </Box>

                  <Divider opacity={1} />

                  <Text>
                    <strong>RUT: </strong>
                    {patient.rut}
                  </Text>
                  <Text>
                    <strong>Previsión: </strong> {patient.prevision}
                  </Text>
                  <Text>
                    <strong>Correo Electrónico: </strong> {patient.email}
                  </Text>
                  <Text>
                    <strong>Teléfono: </strong> {patient.telefono}
                  </Text>
                  <Text>
                    <strong>Dirección: </strong> {patient.direccion}
                  </Text>
                  <Text>
                    <strong>Fecha De Nacimiento: </strong>
                    {patient.fecha_nacimiento}
                  </Text>
                  <Text>
                    <strong>Género: </strong> {patient.sexo}
                  </Text>
                  <Text>
                    <strong>Estado Civil: </strong> {patient.estado_civil}
                  </Text>
                </Stack>
              </CardBody>
            </Card>

            <Box mt={6}>
              {viewReports ? (
                <ReportsScreen idcirugia={idCirugia} setView={handleView} />
              ) : (
                <>
                  <CrearCirugiaUI />
                  <TablaCirugia
                    surgeries={surgeries}
                    idpaciente={idpaciente}
                    setView={handleView}
                  />
                </>
              )}
            </Box>
          </Container>
        </ScaleFade>
      )}
    </>
  );
};
