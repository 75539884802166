/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ProfessionalContext } from "../context/professional/ProfessionalContext";
import { RenderLoading } from "../components/RenderAlert";
import { Box, Container, ScaleFade } from "@chakra-ui/react";
import { FeedbackTabs } from "../components/FeedbackTabs";

export const FeedbackScreen = () => {
  const { getsFeedbacks, feedbacks } = useContext(ProfessionalContext);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await getsFeedbacks();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            shadow={"md"}
            rounded={"md"}
            maxW={"full"}
            py={8}
          >
            <Box mt={6}>
              <FeedbackTabs feedbacks={feedbacks} />
            </Box>
          </Container>
        </ScaleFade>
      )}
    </>
  );
};
