/* 
  maneja las rutas privadas de la aplicacion
*/

import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const PrivateRouter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const [initialRedirectDone, setInitialRedirectDone] = useState(false);

  useEffect(() => {
    const getInitialPath = () => {
      if (isAuthenticated) {
        return "/";
      }

      return "/auth/login";
    };

    // Solo redirige si está autenticado y aún no ha accedido a ninguna ruta
    if (isAuthenticated && !initialRedirectDone) {
      navigate(getInitialPath(), { replace: true });
      setInitialRedirectDone(true);
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("lastPath", location.pathname);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isAuthenticated, initialRedirectDone, navigate, location.pathname]);

  useEffect(() => {
    const lastPath = sessionStorage.getItem("lastPath");
    if (isAuthenticated && lastPath && initialRedirectDone) {
      navigate(lastPath, { replace: true });
    }
  }, [isAuthenticated, navigate, initialRedirectDone]);

  return isAuthenticated ? children : <Navigate to="/auth/login" />;
};

export default PrivateRouter;
