/* 
    maneja todas las funciones del surgery context que engloba todas las acciones asociadas a una cirugia
*/

import React, { useContext, useReducer } from "react";
import { useToast } from "@chakra-ui/react";
import { SurgeryContext } from "./SurgeryContext";
import { SurgeryReducer } from "./SurgeryReducer";
import { API } from "../../api/api";
import AuthContext from "../auth/AuthContext";

export const SurgeryState = ({ children }) => {
  const toast = useToast();
  const { usuario } = useContext(AuthContext);

  const initialState = {
    catheters: [],
    surgeries: [],
    surgery: {},
    type_surgeries: [],
    reports: [],
  };

  const [state, dispatch] = useReducer(SurgeryReducer, initialState);

  const getsCatheters = async () => {
    const endpoint = "/cateter";
    try {
      const { data } = await API.get(endpoint);

      dispatch({
        type: "GETS_CATHETERS",
        payload: data.items,
      });
    } catch (error) {
      handleError(error, "Obtener cateteres");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const createCatheter = async (data) => {
    const endpoint = "/cateter";
    try {
      await API.post(endpoint, data);
      await getsCatheters();

      toast({
        description: "Catéter creado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error, "Crear cateter");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const updateCatheter = async (data) => {
    const endpoint = `/cateter/${data.id}`;
    try {
      await API.put(endpoint, data);
      await getsCatheters();

      toast({
        description: "Datos actualizados exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error, "Actualizar cateter");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const deleteCatheter = async (id) => {
    const endpoint = `/cateter/${id}`;
    try {
      await API.delete(endpoint);
      await getsCatheters();

      toast({
        description: "Se ha eliminado el catéter exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error, "Eliminar cateter");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getsSurgeriesByPatient = async (id) => {
    const endpoint = `/paciente/${id}/cirugias`;
    try {
      const { data } = await API.get(endpoint);

      dispatch({
        type: "GETS_SURGERIES",
        payload: data.cirugias,
      });
    } catch (error) {
      handleError(error, "Obtener cirugias por paciente");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getSurgery = async (id) => {
    const endpoint = `/cirugia/${id}`;
    try {
      const { data } = await API.get(endpoint);

      dispatch({
        type: "GET_SURGERY",
        payload: data.cirugia,
      });
    } catch (error) {
      handleError(error, "Obtener cirugia");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const createSurgery = async (data) => {
    const endpoint = "/cirugia";
    try {
      await API.post(endpoint, data);
      await getsSurgeriesByPatient(data.id_paciente);

      toast({
        description: "Cirugía creada exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error, "Crear cirugia");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getsTypeSurgeries = async () => {
    const endpoint = "/tipo_cirugia";
    try {
      const { data } = await API.get(endpoint);

      dispatch({
        type: "GETS_TYPE_SURGERIES",
        payload: data.items,
      });
    } catch (error) {
      handleError(error, "Obtener tipo de cirugias");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getsReportsBySurgery = async (id) => {
    const endpoint = `/cirugia/${id}/reportes`;
    try {
      const { data } = await API.get(endpoint);

      dispatch({
        type: "GETS_REPORTS",
        payload: data.reportes,
      });
    } catch (error) {
      handleError(error, "Obtener reportes por cirugia");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getsAllReports = async () => {
    const endpoint = "/cuestionario";
    try {
      const { data } = await API.get(endpoint);

      dispatch({
        type: "GETS_REPORTS",
        payload: data.reportes,
      });
    } catch (error) {
      handleError(error, "Obtener reportes");
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleError = async (error, process) => {
    try {
      const bodyLog = {
        correo: usuario.email,
        codigo: error.response.status,
        proceso: process,
        mensaje: error.response.data.message,
        respuesta: error,
      };
      await API.post("/logs", bodyLog);
      console.log(error);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SurgeryContext.Provider
      value={{
        catheters: state.catheters,
        surgeries: state.surgeries,
        surgery: state.surgery,
        type_surgeries: state.type_surgeries,
        reports: state.reports,
        getsCatheters,
        createCatheter,
        updateCatheter,
        deleteCatheter,
        getsSurgeriesByPatient,
        getSurgery,
        createSurgery,
        getsTypeSurgeries,
        getsReportsBySurgery,
        getsAllReports,
      }}
    >
      {children}
    </SurgeryContext.Provider>
  );
};
