/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Flex, SimpleGrid, Stack } from "@chakra-ui/react";
import { ButtonWithIcon } from "../components/CustomButtons";
import { FaReply } from "react-icons/fa";
import { RenderLoading } from "../components/RenderAlert";
import { SurgeryContext } from "../context/surgery/SurgeryContext";
import { ReportCard } from "../components/ReportCard";
// import { ExportToExcel } from "./ExportToExcel";

export const ReportsScreen = ({ idcirugia, setView }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getsReportsBySurgery, reports } = useContext(SurgeryContext);

  useEffect(() => {
    const fetchReports = async (id) => {
      setIsLoading(true);
      await getsReportsBySurgery(id);
      setIsLoading(false);
    };
    fetchReports(idcirugia);
  }, [idcirugia]);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <Box>
          <Flex justify={"flex-start"} align={"flex-end"} mb={4}>
            <Stack spacing={4} direction="row" align="center">
              <ButtonWithIcon
                fn={() => setView("")}
                titleIcon={FaReply}
                titleButton={"Volver"}
                colorScheme="cyan"
                color={"white"}
              />              
              {/* <ExportToExcel reports={reports} /> */}
            </Stack>
          </Flex>

          <Container
            bg="white"
            maxW={"full"}
            mt={2}
            py={6}
            rounded={"md"}
            shadow={"md"}
          >
            <SimpleGrid
              columns={{ base: 2, lg: 3 }}
              spacing={{ base: 5, lg: 8 }}
            >
              {reports.map((report) => (
                <ReportCard key={report.id} report={report} />
              ))}
            </SimpleGrid>
          </Container>
        </Box>
      )}
    </>
  );
};
