import React, { useContext, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { FaCommentDots } from "react-icons/fa";
import { ProfessionalContext } from "../../context/professional/ProfessionalContext";
import AuthContext from "../../context/auth/AuthContext";

export const FeedbackModal = () => {
  const { usuario } = useContext(AuthContext);
  const { createFeedback } = useContext(ProfessionalContext);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setTitle("");
    setComment("");
    setIsOpen(false);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async () => {
    setIsFetching(true);

    const data = {
      titulo: title,
      comentario: comment,
      autor: `${usuario.paciente.nombre} ${usuario.paciente.apellidos}`,
      email: usuario.paciente.email,
    };
    try {
      await createFeedback(data);
      setTitle("");
      setComment("");
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }

    // Cerramos el modal
    handleCloseModal();
  };

  return (
    <>
      <Tooltip hasArrow label="Déjanos una sugerencia">
        <IconButton
          onClick={handleOpenModal}
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FaCommentDots />}
        />
      </Tooltip>

      <Modal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Gestionar feedback</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Título</FormLabel>
              <Input type="text" value={title} onChange={handleTitleChange} />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Comentario</FormLabel>
              <Textarea value={comment} onChange={handleCommentChange} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isFetching}
            >
              Enviar
            </Button>
            <Button ml={2} onClick={handleCloseModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
