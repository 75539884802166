/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Box,
  Icon,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight, FaClipboardList } from "react-icons/fa";
import { ViewSurgeryModal } from "../Modals/ViewSurgeryModal";

export const TablaCirugia = ({ surgeries, setView }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    field: "createdAt",
    direction: "desc",
  });

  const surgeriesPerPage = 5;

  const handleSort = (field) => {
    if (sortConfig.field === field) {
      setSortConfig((prevConfig) => ({
        field,
        direction: prevConfig.direction === "asc" ? "desc" : "asc",
      }));
    } else {
      setSortConfig({ field, direction: "asc" });
    }
  };

  const sortedSurgeries = [...surgeries].sort((a, b) => {
    const { field, direction } = sortConfig;
    if (a[field] < b[field]) return direction === "asc" ? -1 : 1;
    if (a[field] > b[field]) return direction === "asc" ? 1 : -1;
    return 0;
  });

  const totalSurgeries = sortedSurgeries.length;
  const totalPages = Math.ceil(totalSurgeries / surgeriesPerPage);

  const indexOfLastExam = currentPage * surgeriesPerPage;
  const indexOfFirstExam = indexOfLastExam - surgeriesPerPage;
  const currentSurgeries = sortedSurgeries.slice(
    indexOfFirstExam,
    indexOfLastExam
  );

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <TableContainer mt={2} borderRadius={10} shadow={"md"}>
        <Table variant="simple" bg={"white"} borderColor={"black"}>
          <Thead bg="cyan.400">
            <Tr>
              <Th>#</Th>
              <Th>Cirugía</Th>
              <Th onClick={() => handleSort("doctor_tratante")}>Médico</Th>
              <Th onClick={() => handleSort("createdAt")}>Fecha Cirugía</Th>
              <Th>Encuestas</Th>
            </Tr>
          </Thead>

          <Tbody>
            {currentSurgeries.length > 0 ? (
              currentSurgeries.map((surgery, index) => (
                <Tr key={surgery.id}>
                  <Td>{index + 1}</Td>
                  <Td noOfLines={1}>
                    <>
                      {surgery.tipoCirugium.descripcion}
                      <ViewSurgeryModal surgery={surgery} />
                    </>
                  </Td>
                  <Td>{surgery.doctor_tratante}</Td>
                  <Td>{surgery.fecha_creacion}</Td>
                  <Td>
                    {surgery.cuestionarios.length > 0 ? (
                      <Tooltip label="Ver reportes">
                        <IconButton
                          variant="ghost"
                          fontSize="20px"
                          size={"sm"}
                          aria-label="Ver reportes"
                          onClick={() => setView(surgery.id)}
                          icon={<FaClipboardList />}
                        />
                      </Tooltip>
                    ) : (
                      "No hay encuestas"
                    )}
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={6}>No hay cirugías aún</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        {currentPage !== 1 && (
          <Icon
            _hover={{ cursor: "pointer" }}
            as={FaChevronLeft}
            bg={"gray.400"}
            boxSize={7}
            mr={2}
            onClick={goToPreviousPage}
            p={2}
            rounded={"full"}
          />
        )}

        {Array.from({ length: totalPages }).map((_, index) => (
          <Text
            key={index}
            as={"b"}
            fontSize={currentPage === index + 1 ? "2xl" : "sm"}
            color={currentPage === index + 1 ? "gray.600" : "gray.400"}
            mx={2}
          >
            {index + 1}
          </Text>
        ))}

        {currentSurgeries.length === surgeriesPerPage && (
          <Icon
            _hover={{ cursor: "pointer" }}
            as={FaChevronRight}
            bg={"gray.400"}
            boxSize={7}
            ml={2}
            onClick={goToNextPage}
            p={2}
            rounded={"full"}
          />
        )}
      </Box>
    </>
  );
};
